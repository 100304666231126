import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormSelectNonHookForm from 'components/FormNonReactHook/FormSelectNonHookForm';
import { setAccountOverride, setGoogleAPIAuthStatus } from 'services/auth/authSlice';
import api, { useGetAccountsQuery } from 'services/rtkApi';

const TenantSwitcher = () => {
  const { data: accountsData, isLoading } = useGetAccountsQuery({ no_pagination: true });

  const dispatch = useDispatch();
  const accountOverride = useSelector((state) => state.auth.accountOverride);
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(null);

  useEffect(() => {
    if (switchStatus) {
      const timer = setTimeout(() => setSwitchStatus(null), 2000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [switchStatus]);

  const options = [
    { value: null, label: '-- No account selected --' },
    ...(accountsData?.map((account) => ({
      value: account.id,
      label: account.name,
    })) || []),
  ];

  const handleAccountSwitch = async (value) => {
    setIsSwitching(true);
    try {
      await dispatch(setAccountOverride(value));
      dispatch(setGoogleAPIAuthStatus(false));
      dispatch(api.util.resetApiState());
      setSwitchStatus('success');
    } catch (error) {
      setSwitchStatus('error');
      console.error('switch failed:', error);
    } finally {
      setIsSwitching(false);
    }
  };

  return (
    <div className="mt-2 flex items-center gap-2 mb-2">
      <span className="whitespace-nowrap">Account Switcher:</span>
      <FormSelectNonHookForm
        fieldKey="account"
        label="Switch Account"
        showLabel={false}
        value={accountOverride}
        onChange={handleAccountSwitch}
        options={options}
        isLoading={isLoading || isSwitching}
        className="flex-1 min-w-64"
      />
      {switchStatus === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
      {switchStatus === 'error' && <XCircleIcon className="h-5 w-5 text-red-500" />}
    </div>
  );
};

export default TenantSwitcher;
