import { useCallback, useEffect, useState } from 'react';

import { isAddonPrice } from '../pricingUtils';
import { useBasePricingTable } from './usePricingTableBase';

/**
 * Hook to manage pricing table upgrade state and logic
 * @param {Object} params
 * @param {Array} params.initialTiers - Initial pricing tiers
 * @param {Object} params.existingSubscription - Current subscription details
 * @param {Function} params.changeSubscriptionItem - Handler for plan changes
 */
export function usePricingTableUpgrade({
  initialTiers,
  existingSubscription,
  changeSubscriptionItem,
}) {
  const [isChanging, setIsChanging] = useState(false);

  // Use base pricing table hook
  const {
    localTiers,
    billingCycle,
    setBillingCycle,
    error,
    isLoading: isPricesLoading,
    utils,
    currency,
    currencySymbol,
    calculateTotalPrice,
    addonQuantities,
    handleAddonQuantityChange,
  } = useBasePricingTable({ initialTiers });

  // Get the billing cycle from the existing subscription
  useEffect(() => {
    const basePlanItem = existingSubscription?.items?.find((item) => !isAddonPrice(item.price.id));
    const interval = basePlanItem?.price?.billing_cycle?.interval;
    if (interval) {
      setBillingCycle(interval);
    }
  }, [existingSubscription, setBillingCycle]);

  const getBasePlanItem = useCallback((subscription) => {
    return subscription.items.find((item) => !isAddonPrice(item.price.id));
  }, []);

  const handlePlanChange = useCallback(
    async (tier, cycle) => {
      if (!tier?.priceDisplay?.[cycle]) {
        console.error('Invalid tier or billing cycle', { tier, cycle });
        return;
      }

      if (tier.priceDisplay[cycle] === 'Contact Us') {
        window.location.href = '/contact-sales';
        return;
      }

      setIsChanging(true);
      const basePlanItem = getBasePlanItem(existingSubscription);
      const isAnnualToMonthly =
        basePlanItem.price.billing_cycle.interval === 'year' && cycle === 'month';

      const currentPrice = parseFloat(basePlanItem.price.unit_price.amount);
      const newPrice = parseFloat(tier.priceNumeric[cycle]);
      const isDowngrade = newPrice < currentPrice;

      await changeSubscriptionItem({
        id: existingSubscription.id,
        old_price_id: basePlanItem.price.id,
        price_id: tier.priceId[cycle],
        quantity: 1,
        proration_billing_mode:
          isAnnualToMonthly || isDowngrade ? 'full_next_billing_period' : 'prorated_immediately',
      });
    },
    [existingSubscription, changeSubscriptionItem, getBasePlanItem]
  );

  const getButtonText = useCallback(
    (tier, billingCycle) => {
      if (!tier?.priceDisplay || !billingCycle) {
        console.error('Invalid tier or billing cycle in getButtonText', { tier, billingCycle });
        return 'Error';
      }

      if (tier.priceDisplay[billingCycle] === 'Contact Us') {
        return 'Contact Us';
      }

      if (!existingSubscription) {
        console.error('No existing subscription in getButtonText');
        return 'Error';
      }

      const basePlanItem = getBasePlanItem(existingSubscription);
      if (!basePlanItem?.price?.id || !tier.priceId?.[billingCycle]) {
        console.error('Missing price IDs', { basePlanItem, tier, cycle: billingCycle });
        return 'Error';
      }

      return basePlanItem.price.id === tier.priceId[billingCycle] ? 'Current Plan' : 'Change Plan';
    },
    [existingSubscription, getBasePlanItem]
  );

  return {
    // State from base hook
    localTiers,
    billingCycle,
    setBillingCycle,
    error,
    isPricesLoading,
    utils,
    currency,
    currencySymbol,

    // Pricing
    calculateTotalPrice,

    // Upgrade specific state and actions
    isChanging,
    handlePlanChange,
    getButtonText,

    // Add addon state
    addonQuantities,
    handleAddonQuantityChange,
  };
}
