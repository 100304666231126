import { setAuthToken, setStateAccessor } from 'services/auth/authApi';
import store from 'store'; // Adjust the import based on your store setup

/**
 * Initializes the auth token from the store
 */
const initializeAuthToken = () => {
  const state = store.getState();
  const { accessToken } = state.auth; // Adjust based on your state structure

  if (accessToken) {
    setAuthToken(accessToken, 'JWT');
  }
};

/**
 * Subscribes to the store and initializes the auth token once rehydration is complete
 */
export const rehydrateAuthToken = () => {
  const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    if (state._persist.rehydrated) {
      initializeAuthToken();
      unsubscribe();
    }
  });
};

/**
 * Initializes the authApi with access to the Redux store
 * This breaks the circular dependency by initializing after the store is created
 */
const initializeAuthApi = () => {
  // Set the state accessor function to access Redux state
  setStateAccessor(() => store.getState());

  // Also initialize the auth token
  initializeAuthToken();
};

export default initializeAuthApi;
