import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import FormInput from 'components/Forms/FormInput/FormInput';
import LoggedOutLegalLinks from 'components/LegalLinks/LoggedOutLegalLinks';
import SignedOutContainer from 'components/SignedOutContainer/SignedOutContainer';
import { showErrorToast, showSuccessToast } from 'components/Toaster/ToastUtils';
import { LOGIN } from 'routes/constants';
import { useAcceptInvitationMutation } from 'services/rtkApi';
import LoggedOutLayout from 'templates/LoggedOutLayout';

/**
 * Page for accepting an invitation and setting a password
 */
const AcceptInvitation = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const watchPassword = watch('password', '');
  const [acceptInvitation] = useAcceptInvitationMutation();

  /**
   * Validates that invitation parameters exist
   */
  useEffect(() => {
    if (!uid || !token) {
      showErrorToast('Invalid invitation link. Please check your email and try again.');
      navigate(LOGIN);
    }
  }, [uid, token, navigate]);

  /**
   * Handle form submission to accept the invitation
   */
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      await acceptInvitation({
        token,
        uid,
        password: data.password,
      }).unwrap();

      showSuccessToast('Account setup complete! You can now log in.');
      navigate(LOGIN);
    } catch (err) {
      console.error('Error accepting invitation:', err);
      showErrorToast(
        err.data?.detail || 'Failed to accept invitation. It may have expired or been cancelled.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LoggedOutLayout>
      <SignedOutContainer>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Accept Invitation
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Please set a password to complete your account setup
          </p>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="sm:mx-auto sm:w-full sm:max-w-sm space-y-6"
        >
          <FormInput
            fieldKey="password"
            label="Password"
            type="password"
            register={register}
            errors={errors.password}
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            })}
          />

          <FormInput
            fieldKey="confirmPassword"
            label="Confirm Password"
            type="password"
            register={register}
            errors={errors.confirmPassword}
            {...register('confirmPassword', {
              required: 'Please confirm your password',
              validate: (value) => value === watchPassword || 'The passwords do not match',
            })}
          />

          <Button
            type="submit"
            variant="primary"
            className="w-full"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Set Password & Accept Invitation
          </Button>

          <div>
            <LoggedOutLegalLinks />
          </div>
        </form>
      </SignedOutContainer>
    </LoggedOutLayout>
  );
};

export default AcceptInvitation;
