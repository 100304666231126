import { initializePaddle } from '@paddle/paddle-js';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PADDLE_CONFIG } from './paddleConstants';

const CURRENCY_SYMBOLS = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  AUD: 'A$',
  CAD: 'C$',
  // Add more as needed
};

const truncatePrice = (price) => {
  if (price.endsWith('.00')) {
    return price.slice(0, -3);
  }
  return price;
};

const formatPriceValue = (amount) => {
  // Convert from smallest currency unit (pence/cents) to main currency unit (pounds/dollars)
  return amount / 100;
};

export function usePaddlePrices(initialTiers, onEventCallback) {
  // Set-up our states
  const [paddle, setPaddle] = useState(null);
  const [tiers, setTiers] = useState(null);
  const [billingCycle, setBillingCycle] = useState('month');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPricesFetched, setIsPricesFetched] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);

  // Use refs to store the latest values without causing re-renders
  const billingCycleRef = useRef(billingCycle);
  const tiersRef = useRef(tiers);

  // Update refs when state changes
  useEffect(() => {
    billingCycleRef.current = billingCycle;
    tiersRef.current = tiers;
  }, [billingCycle, tiers]);

  // Kick off Paddle initialization
  useEffect(() => {
    initializePaddle({
      environment: PADDLE_CONFIG.ENVIRONMENT,
      token: PADDLE_CONFIG.CLIENT_TOKEN,
      eventCallback(data) {
        // Call custom callback if provided
        onEventCallback?.(data);
      },
    })
      .then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        } else {
          setError('Failed to initialize Paddle');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(`Failed to initialize Paddle: ${err.message}`);
        setIsLoading(false);
      });
  }, [onEventCallback]);

  const fetchPrices = useCallback(async () => {
    if (!paddle) return;

    setIsLoading(true);
    setError(null);

    try {
      const currentTiers = tiersRef.current;

      const updatedTiers = await Promise.all(
        currentTiers.map(async (tier) => {
          // If original tier has "Contact Us" pricing, preserve both display and numeric values
          if (
            tier.priceDisplay?.month === 'Contact Us' ||
            tier.priceDisplay?.year === 'Contact Us'
          ) {
            return {
              ...tier,
              priceDisplay: {
                month: 'Contact Us',
                year: 'Contact Us',
              },
              priceNumeric: {
                month: 0,
                year: 0,
              },
            };
          }

          const priceDisplay = {};
          const priceNumeric = {};
          const cycles = ['month', 'year'];

          await Promise.all(
            cycles.map(async (cycle) => {
              if (tier.priceId && tier.priceId[cycle]) {
                try {
                  const request = {
                    items: [{ priceId: tier.priceId[cycle], quantity: 1 }],
                  };
                  const result = await paddle.PricePreview(request);
                  const item = result.data.details.lineItems[0];

                  // Store currency from first successful price fetch
                  if (!currency) {
                    const code = result.data.currencyCode;
                    setCurrency(code);
                    setCurrencySymbol(CURRENCY_SYMBOLS[code] || code);
                  }

                  priceDisplay[cycle] = truncatePrice(item?.formattedUnitTotals?.subtotal);
                  priceNumeric[cycle] = formatPriceValue(item?.unitTotals?.subtotal || 0);
                } catch (error) {
                  console.error(`Error fetching price for ${tier.name}:`, error);
                  priceDisplay[cycle] = 'Price unavailable';
                  priceNumeric[cycle] = 0;
                }
              } else {
                priceDisplay[cycle] = 'Contact Us';
                priceNumeric[cycle] = 0;
              }
            })
          );

          // Handle addon prices if they exist
          if (tier.addons) {
            const updatedAddons = await Promise.all(
              tier.addons.map(async (addon) => {
                const addonPriceDisplay = {};
                const addonPriceNumeric = {};
                const cycles = ['month', 'year'];

                await Promise.all(
                  cycles.map(async (cycle) => {
                    if (addon.priceId && addon.priceId[cycle]) {
                      try {
                        const request = {
                          items: [{ priceId: addon.priceId[cycle], quantity: 1 }],
                        };
                        const result = await paddle.PricePreview(request);
                        const item = result.data.details.lineItems[0];

                        addonPriceDisplay[cycle] = truncatePrice(
                          item?.formattedUnitTotals?.subtotal
                        );
                        addonPriceNumeric[cycle] = formatPriceValue(
                          item?.unitTotals?.subtotal || 0
                        );
                      } catch (error) {
                        console.error(`Error fetching addon price for ${addon.name}:`, error);
                        addonPriceDisplay[cycle] = 'Price unavailable';
                        addonPriceNumeric[cycle] = 0;
                      }
                    }
                  })
                );

                return {
                  ...addon,
                  priceDisplay: addonPriceDisplay,
                  priceNumeric: addonPriceNumeric,
                };
              })
            );

            return {
              ...tier,
              priceDisplay,
              priceNumeric,
              addons: updatedAddons,
            };
          }

          return {
            ...tier,
            priceDisplay,
            priceNumeric,
          };
        })
      );

      // Update the prices
      setTiers(updatedTiers);
      setIsPricesFetched(true);
    } catch (err) {
      setError(`Failed to fetch prices: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [paddle, currency]);

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Debounced version of fetchPrices
  const debouncedFetchPrices = useCallback(debounce(fetchPrices, 300), [fetchPrices]);

  useEffect(() => {
    if (paddle) {
      setTiers(initialTiers);
      debouncedFetchPrices();
    }
  }, [paddle, debouncedFetchPrices, initialTiers]);

  return {
    tiers,
    paddle,
    billingCycle,
    setBillingCycle,
    error,
    isLoading: isLoading || !isPricesFetched,
    currency,
    currencySymbol,
  };
}
