// In some page component

import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import {
  ACCOUNT_USAGE,
  GOOGLE_API_PERMISSIONS_REGISTER,
  PLAN_MANAGEMENT,
  USER_INVITATIONS,
  USER_SETTINGS,
  USER_SHARING,
} from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const AccountSettings = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Settings</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="space-y-6 mt-6">
            <CommonActionSingle
              title="Manage your plan"
              description="Need to change your plan, upgrade or cancel? Do it here."
              buttonName="Manage Plan"
              href={PLAN_MANAGEMENT}
            />
            <CommonActionSingle
              title="Account Usage"
              description="Check how much you've used of your plan limit."
              buttonName="View Usage"
              href={ACCOUNT_USAGE}
            />
            <CommonActionSingle
              title="Permissions"
              description="In order for Pipeline to work we need permission to work with your GA4 and BigQuery. Here's where you share access."
              buttonName="Manage Permissions"
              href={GOOGLE_API_PERMISSIONS_REGISTER}
            />
            <CommonActionSingle
              title="User"
              description="Manage your user settings and view your account details."
              buttonName="Manage User"
              href={USER_SETTINGS}
            />
            <CommonActionSingle
              title="User Sharing"
              description="Share your account with other users."
              buttonName="Manage User Sharing"
              href={USER_SHARING}
            />
            <CommonActionSingle
              title="User Invitations"
              description="Invite new users to join your account."
              buttonName="Manage Invitations"
              href={USER_INVITATIONS}
            />
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default AccountSettings;
