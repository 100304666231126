import PropTypes from 'prop-types';
import React from 'react';

import EmptyState from 'components/EmptyState/EmptyState';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TextBubble from 'components/TextBubble/TextBubble';
import { PIPELINE_RUN_VIEW } from 'routes/constants';
import { useGetCurrentUserQuery, useSetPipelineRunStatusMutation } from 'services/rtkApi';

/**
 * Table component for displaying pipeline runs
 */
const PipelineRunTable = ({ runs, isLoading, isError }) => {
  // Get current user for admin features.
  const { data: user } = useGetCurrentUserQuery();
  const [setPipelineRunStatus, { isLoading: isStatusLoading }] = useSetPipelineRunStatusMutation();

  /**
   * Define columns for the table
   */
  const columns = React.useMemo(
    () => [
      {
        Header: 'Run Status',
        accessor: 'did_run_succeed',
        Cell: ({ value }) => {
          let color = 'grey';
          let text = 'In Progress';
          if (value === true) {
            color = 'green';
            text = 'Success';
          } else if (value === false) {
            color = 'red';
            text = 'Failed';
          }
          return <TextBubble color={color} text={text} />;
        },
      },
      {
        Header: 'In Progress',
        accessor: 'is_run_in_progress',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Duration',
        accessor: (row) => {
          if (!row.completed_at || !row.started_at) return '-';
          const duration = new Date(row.completed_at) - new Date(row.started_at);
          const minutes = Math.floor(duration / 60000);
          const seconds = Math.floor((duration % 60000) / 1000);
          return `${minutes}m ${seconds}s`;
          // Convert ms to minutes and seconds
        },
        id: 'duration',
      },
      {
        Header: 'Started At',
        accessor: 'started_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: 'Completed At',
        accessor: 'completed_at',
        Cell: ({ value }) => (value ? new Date(value).toLocaleString() : '-'),
      },
    ],
    []
  );

  /**
   * Define link buttons for the table
   */
  const linkButtons = [
    {
      label: 'View Run',
      link: PIPELINE_RUN_VIEW,
      show: true,
    },
    {
      label: isStatusLoading ? <LoadingSpinner size={4} /> : 'Set Failed',
      onClick: async (row) => {
        await setPipelineRunStatus({
          id: row.id,
          did_run_succeed: false,
          run_summary: 'Set to failed by admin',
        });
      },
      show: user?.is_superuser && !isLoading,
      disabled: isStatusLoading,
    },
  ];

  if (isLoading) return <LoadingSpinner text="Loading pipeline runs..." />;
  if (isError) return <div>Error loading pipeline runs</div>;

  if (!runs || runs.length === 0) {
    return (
      <EmptyState title="No pipeline runs found" description="This pipeline hasn't been run yet." />
    );
  }

  return (
    <FlexListTable
      columns={columns}
      data={runs}
      linkButtons={linkButtons}
      hiddenColumnIds={['id']}
      initialSort={[{ id: 'started_at', desc: true }]}
    />
  );
};

PipelineRunTable.propTypes = {
  // pipelineId is passed from the parent but used only for API calls in the parent component
  pipelineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      did_run_succeed: PropTypes.bool,
      is_run_in_progress: PropTypes.bool,
      started_at: PropTypes.string,
      completed_at: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default PipelineRunTable;
