import { useState } from 'react';

import Button from 'components/Button/Button';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import FormInputNonHookForm from 'components/FormNonReactHook/FormInputNonHookForm';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import {
  useGetUsersWithAccessQuery,
  useRemoveAccessMutation,
  useShareAccessMutation,
} from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';
import { cleanDRFError } from 'utils/dataUtils';

const UserSharingPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const { data: users = [], isLoading } = useGetUsersWithAccessQuery();
  const [shareAccess, { isLoading: isSharing }] = useShareAccessMutation();
  const [removeAccess] = useRemoveAccessMutation();

  const handleShare = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await shareAccess(email).unwrap();
      setEmail('');
    } catch (err) {
      setError(cleanDRFError(err.data?.error, 'Failed to share access'));
    }
  };

  const handleRemoveAccess = async (rowData) => {
    try {
      await removeAccess(rowData.id).unwrap();
    } catch (err) {
      setError(cleanDRFError(err.data?.error, 'Failed to remove access'));
    }
  };

  const columns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
  ];

  const linkButtons = [
    {
      label: 'Remove Access',
      onClick: handleRemoveAccess,
      show: true,
    },
  ];

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Share Account Access</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="space-y-6 mt-4">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Share access with another user
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Enter the email address of the user you want to share access with.</p>
                </div>
                <form onSubmit={handleShare} className="mt-5 sm:flex sm:items-center">
                  <div className="w-full sm:max-w-xs">
                    <FormInputNonHookForm
                      fieldKey="email"
                      label="Email"
                      value={email}
                      onChange={setEmail}
                      type="email"
                      showLabel={false}
                      placeholder="you@example.com"
                      disabled={isSharing}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="mt-3 sm:mt-0 sm:ml-4"
                    disabled={isSharing}
                  >
                    {isSharing ? 'Sharing...' : 'Share Access'}
                  </Button>
                </form>
                {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
              </div>
            </div>

            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900 mb-4">
                  Users with access
                </h3>
                {isLoading ? (
                  <LoadingSpinner text="Loading users..." />
                ) : (
                  <FlexListTable columns={columns} data={users} linkButtons={linkButtons} />
                )}
              </div>
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default UserSharingPage;
