import PropTypes from 'prop-types';

/**
 * Card displaying pipeline statistics
 */
const PipelineStatsCard = ({ stats }) => {
  if (!stats) return null;

  const { totalPipelines, totalRuns, successfulRuns, failedRuns } = stats;

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt className="truncate text-sm font-medium text-gray-500">Total Pipelines</dt>
        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          {totalPipelines}
        </dd>
      </div>

      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt className="truncate text-sm font-medium text-gray-500">Total Runs (Yesterday)</dt>
        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{totalRuns}</dd>
      </div>

      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt className="truncate text-sm font-medium text-gray-500">Successful Runs</dt>
        <dd className="mt-1 text-3xl font-semibold tracking-tight text-green-600">
          {successfulRuns}
        </dd>
      </div>

      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt className="truncate text-sm font-medium text-gray-500">Failed Runs</dt>
        <dd className="mt-1 text-3xl font-semibold tracking-tight text-red-600">{failedRuns}</dd>
      </div>
    </div>
  );
};

PipelineStatsCard.propTypes = {
  stats: PropTypes.shape({
    totalPipelines: PropTypes.number.isRequired,
    totalRuns: PropTypes.number.isRequired,
    successfulRuns: PropTypes.number.isRequired,
    failedRuns: PropTypes.number.isRequired,
  }),
};

export default PipelineStatsCard;
