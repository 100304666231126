import { z } from 'zod';

const parameterToUnnestSchema = z.array(
  z.object({
    event_parameter: z.string(),
    name: z.string(),
    value_type: z.enum(['string_value', 'int_value', 'float_value']).describe({
      string: 'String',
      int: 'Integer',
      float: 'Float',
    }),
    force_to_str: z.boolean(),
  })
);
/**
 * DEFINTIION: Ecommerce
 */
export const sourceGA4BigQueryEcommerceSchemaForm = z.object({
  // // Are we using ecommerce features?  -- Auto
  ecommerce: z.boolean().optional(),
  // // Which ecommerce events?
  item_properties_to_unnest: parameterToUnnestSchema.optional(),
});

export const fieldInfoSourceGA4DBTEcommerce = {
  id: { name: 'ID' },
  ecommerce: {
    name: 'Ecommerce',
    description: 'Which ecommerce events do we want to process?',
  },
  item_properties_to_unnest: {
    name: 'Extra Item Properties',
    description: 'Which extra pieces of information do we want from our items?',
    showLabel: false,
    showDescription: false,
    showArrayLabel: false,
    showArrayDescription: false,
  },
};
