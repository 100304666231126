import { zodResolver } from '@hookform/resolvers/zod';
import { createContext, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getDefaultValue } from 'components/Forms/formUtils';
import { pipelineSchemaForm } from 'pages/models/pipelines/schema/pipelineSchema';

const PipelineFormContext = createContext(null);

export const PipelineFormStateProvider = ({ children, isUpdate = false }) => {
  const initialValues = {
    timezone: '+00:00',
    run_hour: 8,
  };

  // Only get schema defaults if we're not updating
  const schemaDefaults = useMemo(
    () => (!isUpdate ? getDefaultValue(pipelineSchemaForm) : {}),
    [isUpdate]
  );

  const methods = useForm({
    resolver: zodResolver(pipelineSchemaForm),
    mode: 'onBlur',
    defaultValues: !isUpdate ? schemaDefaults : {},
  });

  return (
    <PipelineFormContext.Provider value={methods}>
      <FormProvider {...methods}>{children}</FormProvider>
    </PipelineFormContext.Provider>
  );
};

export const usePipelineFormState = () => {
  const context = useContext(PipelineFormContext);
  if (!context) {
    throw new Error('usePipelineFormState must be used within a PipelineFormStateProvider');
  }
  return context;
};
