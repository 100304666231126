// Import your djangoApiHelpers instance

import authApi from 'services/auth/authApi';

export const login = async (email, password) => {
  const response = await authApi.post('/api/v1/auth/jwt/create/', {
    email,
    password,
  });
  return response.data;
};

export const useRefreshToken = async (refresh) => {
  const response = await authApi.post('/api/v1/auth/jwt/refresh/', {
    refresh,
  });

  return response.data;
};

export const verifyToken = async (token) => {
  const response = await authApi.post('/api/v1/auth/jwt/verify/', {
    token,
  });
  return response.data;
};

export const socialLogin = async (provider, code, state) => {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('state', state);

  const response = await authApi.post(`/api/v1/auth/o/${provider}/`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  // Extract additional information from the response
  const { access, refresh, user } = response.data;
  return { access, refresh, email: user };
};

export const getSocialRedirectUrl = async (provider, redirect_uri) => {
  const response = await authApi.get(`/api/v1/auth/o/${provider}/?redirect_uri=${redirect_uri}`);
  return response.data;
};

export const logout_backend = async () => {
  // Delete the token

  const response = await authApi.post('/api/v1/auth/token/logout/');
  return response.data;
};

export const register = async (userData) => {
  const response = await authApi.post('/api/v1/auth/user/', userData);
  return response.data;
};

export const checkUserExists = async (email) => {
  const response = await authApi.get(`/api/v1/auth/user/check-exists/?email=${email}`);
  return response.data.length > 0; // Returns true if user exists, otherwise false
};

// Add the activateAccount function
export const activateAccount = async (uid, token) => {
  const response = await authApi.post('/api/v1/auth/user/activation/', { uid, token });
  return response.data;
};

export const forgotPassword = async (email) => {
  const response = await authApi.post('/api/v1/auth/user/reset_password/', { email });
  return response.data;
};

export const resetPasswordConfirm = async (uid, token, new_password) => {
  const response = await authApi.post('/api/v1/auth/user/reset_password_confirm/', {
    uid,
    token,
    new_password,
  });
  return response.data;
};

/**
 * OAuth 2
 */

export const getGoogleAPIAuthGetRedirectUrl = async () => {
  const response = await authApi.get('/api/v1/google-auth-flow/get-redirect/');
  return response.data.authorization_url;
};

export const getGoogleAPIAuthStatus = async () => {
  const response = await authApi.get('/api/v1/google-auth-flow/status/');
  return response.data;
};

export const googleAPIAuthLogin = async (code, state) => {
  const response = await authApi.post(
    '/api/v1/google-auth-flow/callback/',
    { code, state },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const { access_token, refresh_token } = response.data;
  return { access: access_token, refresh: refresh_token };
};
