import { useGetCurrentUserQuery } from 'services/rtkApi';

/**
 * Hook to check user permissions (staff/superuser status)
 * Can either use a passed user object or fetch the current user
 *
 * @param {Object} options
 * @param {Object} [options.user] - Optional user object to check permissions against
 * @returns {Object} Object containing permission checks and loading state
 */
export const useUserPermissions = ({ user } = {}) => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery(undefined, {
    skip: !!user, // Skip the query if we were passed a user
  });

  const userToCheck = user || currentUser;

  const isStaff = !!userToCheck?.is_staff;
  const isSuperuser = !!userToCheck?.is_superuser;

  return {
    isStaff,
    isSuperuser,
    isAdmin: isStaff || isSuperuser, // Combined admin check
    isLoading: isLoading && !user, // Only consider loading if we're fetching and weren't passed a user
    user: userToCheck,
  };
};
