import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Show a success toast notification
 * @param {string} message - The message to display
 * @param {Object} [options] - Additional react-toastify options
 */
export const showSuccessToast = (message, options = {}) => {
  toast.success(message, {
    autoClose: 5000,
    ...options,
  });
};

/**
 * Show an error toast notification
 * @param {string} message - The message to display
 * @param {Object} [options] - Additional react-toastify options
 */
export const showErrorToast = (message, options = {}) => {
  toast.error(message, {
    autoClose: 5000,
    ...options,
  });
};

/**
 * Show an info toast notification
 * @param {string} message - The message to display
 * @param {Object} [options] - Additional react-toastify options
 */
export const showInfoToast = (message, options = {}) => {
  toast.info(message, {
    autoClose: 5000,
    ...options,
  });
};
