import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import FormPropertyList from 'components/Forms/FormPropertyList/FormPropertyList';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useConvertCustomPropertiesValuesToString from 'pages/models/source_ga4_bigquery/_hooks/useConvertCustomPropertiesValuesToString';
import useGA4TableSchema from 'pages/models/source_ga4_bigquery/_hooks/useGA4TableSchema';
import useSourceGA4BigQuerySchemaOptions from 'pages/models/source_ga4_bigquery/_hooks/useSourceGA4BigQuerySchemaOptions';
import {
  fieldInfoSourceGA4DBTUserProperties,
  sourceGA4BigQueryUserPropertiesBoolsSchemaForm,
  sourceGA4BigQueryUserPropertiesNumberSchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageUserProperties/schema/sourceGA4DBTUserPropertiesSchema';
import { useGetDbtProjectFileQuery } from 'services/rtkApi';

import useGA4UserPropertyTemplates, {
  emptyUserTemplateBool,
  emptyUserTemplateStringNumber,
} from '../hooks/useGA4UserPropertyTemplates';

import SourceGA4BigQueryFormSingleUserProperty from './SourceGA4BigQueryFormSingleUserProperty';

// Define a lookup constant for schemas
const SCHEMA_LOOKUP = {
  default: {
    schema: sourceGA4BigQueryUserPropertiesBoolsSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTUserProperties,
  },
  string_number: {
    schema: sourceGA4BigQueryUserPropertiesNumberSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTUserProperties,
  },
  bool: {
    schema: sourceGA4BigQueryUserPropertiesBoolsSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTUserProperties,
  },
};

/**
 * SourceGA4BigQueryFormAllUserProperties component for managing all user properties.
 */
const SourceGA4BigQueryFormAllUserProperties = () => {
  const { id, pipelineId } = useParams();

  // Go and get table schema for the form.
  const { tableSchema, isLoading: isLoadingTableSchema } = useGA4TableSchema(pipelineId);

  // Get foreign key options at the top level
  const { foreignKeyOptions, loadingForeignKeys } = useSourceGA4BigQuerySchemaOptions(
    tableSchema,
    isLoadingTableSchema
  );

  // Get the form data
  const { data: formData, isLoading: isLoadingForm, error } = useGetDbtProjectFileQuery(id);

  // Extract user_properties and default to an empty array
  const raw_user_properties = formData?.derived_user_properties || [];

  // Because we don't know if we're expecting a string or number, the validation seems to struggle with
  // it. So we convert all to strings. Then when we submit intelligently convert back.
  const user_properties = useConvertCustomPropertiesValuesToString(raw_user_properties);

  /**
   * Get actions and state with the actions.
   */
  const { emptyStateActions, handleAddUserProperty, handleDeleteUserProperty, isUpdating } =
    useGA4UserPropertyTemplates(id, user_properties, formData?.derived_user_properties || []);

  // Update items to include the schema_template
  const items = useMemo(() => {
    return user_properties.map((prop, index) => ({
      id: index,
      name: prop.name,
      index,
      schema_template: prop.schema_template || 'default',
    }));
  }, [user_properties]);

  /**
   * Bottom actions to be shown below the templates.
   */
  const bottomActions = [
    {
      text: 'Add user property (string/number)',
      description: "Add a user property like 'total lifetime value' or 'favorite product category'",
      action: () => handleAddUserProperty(emptyUserTemplateStringNumber),
    },
    {
      text: 'Add user property (true/false)',
      description: "Add a user property like 'is premium member' or 'has completed onboarding'",
      action: () => handleAddUserProperty(emptyUserTemplateBool),
    },
  ];

  if (isLoadingForm || isUpdating || isLoadingTableSchema || loadingForeignKeys.length > 0) {
    return <LoadingSpinner text="Loading user properties..." />;
  }
  if (error) return <p>Error loading data</p>;

  return (
    <FormPropertyList
      items={items}
      emptyStateTitle="Add example user property template"
      emptyStateDescription="Add a user property to get started."
      emptyStateActions={emptyStateActions}
      emptyProjectText="Add custom user property"
      emptyProjectAction={() =>
        handleAddUserProperty({ name: 'New User Property', propertyConditionBlock: {} })
      }
      onDeleteItem={handleDeleteUserProperty}
      bottomActions={bottomActions}
      isLoading={isUpdating}
    >
      {(selectedItem) => {
        const { schema, fieldInfo } =
          SCHEMA_LOOKUP[selectedItem.schema_template] || SCHEMA_LOOKUP.default;

        return (
          <SourceGA4BigQueryFormSingleUserProperty
            id={id}
            tableSchema={tableSchema}
            selectedPropertyIndex={selectedItem.index}
            allProperties={user_properties}
            schema={schema}
            fieldInfo={fieldInfo}
            foreignKeyOptions={foreignKeyOptions}
            loadingForeignKeys={loadingForeignKeys}
          />
        );
      }}
    </FormPropertyList>
  );
};

export default SourceGA4BigQueryFormAllUserProperties;
