import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';

import GlobalDrawer from 'components/DrawerGlobal/DrawerGlobal';
import ErrorBoundaryHomeRedirect from 'components/ErrorBoundaryHomeRedirect/ErrorBoundaryHomeRedirect';
import ErrorToaster from 'components/ErrorToaster/ErrorToaster';
import TawkTo from 'components/TawkTo/TawkTo';
import Toaster from 'components/Toaster/Toaster';
import { useAppInitialization } from 'hooks/useAppInitialisation';
import AppRoutes from 'routes';
import initializeAuthApi, { rehydrateAuthToken } from 'services/auth/authApiInitialize';
import BaseLayout from 'templates/BaseLayout';
import SentryBoundary from 'utils/SentryBoundary';

import store, { history, persistor } from './store';

// Initialize auth API with access to the store
// This must happen after the store is imported but before any components render
initializeAuthApi();

// Also set up rehydration listener for persisted state
rehydrateAuthToken();

const AppInitializer = () => {
  const { error } = useAppInitialization();

  if (error) {
    console.error('Failed to fetch Google API status:', error);
    return null;
  }

  return null;
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <SentryBoundary>
          <ErrorBoundaryHomeRedirect>
            <AppInitializer />
            <ErrorToaster />
            <Toaster />
            <BaseLayout>
              <GlobalDrawer />
              <AppRoutes />
              <TawkTo />
            </BaseLayout>
          </ErrorBoundaryHomeRedirect>
        </SentryBoundary>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
