import { useCallback, useEffect, useState } from 'react';

import { usePaddlePrices } from 'hooks/paddle/usePaddlePrices';

/**
 * Base hook for pricing table functionality shared between new subscriptions and upgrades
 * @param {Object} props
 * @param {Array} props.initialTiers - Initial pricing tiers
 * @param {Function} props.onPaddleEvent - Optional callback for paddle events
 */
export const useBasePricingTable = ({ initialTiers, onPaddleEvent }) => {
  // Common state
  const [localTiers, setLocalTiers] = useState([]);
  const [addonQuantities, setAddonQuantities] = useState({});

  // Here's an example of what paddle prices returns for a tier
  // [
  //   {
  //     name: 'Do It Yourself',
  //     description: 'Perfect for agencies or businesses who want to self serve.',
  //     id: 'tier-basic',
  //     href: '#',
  //     priceDisplay: {
  //       month: '£150',
  //       year: '£1500',
  //     },
  //     mostPopular: false,
  //     priceId: {
  //       month: 'pri_01j5z8vtdv0mpa3qzhyrtyywcv',
  //       year: 'pri_01j5z8xsmnsa1mdchhd7be3q0w',
  //     },
  //     addons: [
  //       {
  //         name: 'Add Pipeline & Events',
  //         id: 'additional-property',
  //         description: 'Add 1 pipeline & increase daily event limit 500k.',
  //         priceId: {
  //           month: 'pri_01jbcrynhv7p2bav06rkwtqc5g',
  //           year: 'pri_01jcycnf36ae776cfs2ens6110',
  //         },
  //         priceDisplay: {
  //           month: '£30',
  //           year: '£300',
  //         },
  //       },
  //     ],
  //     slider: {
  //       addon_id: 'additional-property',
  //       min: 0,
  //       max: 10,
  //       default: 0,
  //       features: [
  //         {
  //           id: 'pipelines',
  //           increment: 1,
  //         },
  //         {
  //           id: 'max-daily-events',
  //           increment: 500000,
  //         },
  //       ],
  //     },
  //   }
  // ];
  const {
    tiers,
    billingCycle,
    setBillingCycle,
    error,
    isLoading,
    currency,
    currencySymbol,
    paddle,
  } = usePaddlePrices(initialTiers, onPaddleEvent);

  // Effect to update localTiers
  useEffect(() => {
    if (tiers) {
      setLocalTiers(tiers);
    }
  }, [tiers]);

  // Handler for addon updates
  const handleAddonQuantityChange = useCallback((tierId, addonId, quantity) => {
    setAddonQuantities((prev) => ({
      ...prev,
      [tierId]: {
        ...prev[tierId],
        [addonId]: quantity,
      },
    }));
  }, []);

  // Calculate total price including addons
  const calculateTotalPrice = useCallback(
    (tier, cycle) => {
      // Check for "Contact Us" pricing
      if (tier?.priceDisplay?.[cycle] === 'Contact Us') {
        return 'Contact Us';
      }

      if (!tier?.priceNumeric || !cycle) return 0;

      // Base price
      const basePrice = tier.priceNumeric[cycle] || 0;
      const tierAddons = addonQuantities[tier.id] || {};

      // Calculate addon total with more explicit price handling
      const addonTotal = Object.entries(tierAddons).reduce((sum, [addonId, quantity]) => {
        const addon = tier.addons?.find((a) => a.id === addonId);
        if (!addon) return sum;

        const addonPrice =
          addon.priceNumeric?.[cycle] || addon.prices?.[cycle] || addon.unit_price?.[cycle] || 0;

        return sum + addonPrice * quantity;
      }, 0);

      const total = basePrice + addonTotal;
      return total;
    },
    [addonQuantities]
  );

  // Shared utility functions
  const utils = {
    getBillingCycleText: (billingCycle, priceDisplay) => {
      if (priceDisplay === 'Contact Us' || priceDisplay === 'Price unavailable') return '';
      return billingCycle === 'month' ? '/month' : '/year';
    },

    getTextSizeClass: (priceDisplay, isError) => {
      if (priceDisplay === 'Contact Us' || isError) return 'text-2xl';
      return 'text-4xl';
    },

    classNames: (...classes) => {
      return classes.filter(Boolean).join(' ');
    },
  };

  return {
    localTiers,
    billingCycle,
    setBillingCycle,
    error,
    isLoading,
    utils,
    currency,
    currencySymbol,
    paddle,
    // Add addon and pricing calculation
    addonQuantities,
    handleAddonQuantityChange,
    calculateTotalPrice,
  };
};
