import {
  CursorArrowRaysIcon,
  DocumentArrowDownIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';

import { useUpdateDbtProjectFileListPropertyMutation } from 'services/rtkApi';

/**
 * Empty conversion template.
 */
export const emptyConversion = {
  name: 'new_conversion',
  propertyConditionBlock: {
    propertyConditions: [
      {
        conditionBlock: {
          andOr: 'and',
          conditions: [],
        },
        value: {
          col: undefined,
          on_condition_true: '',
        },
      },
    ],
  },
};

/**
 * Hook for managing GA4 conversion templates and actions.
 *
 * @param {string} id - The project ID
 * @param {Array} initialConversionEvents - The initial conversion events
 * @param {Array} currentConversionEvents - The current conversion events from parent
 * @returns {Object} An object containing emptyStateActions, handleAddConversion, handleDeleteConversion, and isUpdating
 */
const useGA4ConversionTemplates = (id, initialConversionEvents, currentConversionEvents) => {
  const [updateDbtProjectFileListProperty] = useUpdateDbtProjectFileListPropertyMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [conversionEvents, setConversionEvents] = useState(initialConversionEvents);

  const conversionTemplates = [
    {
      title: 'First Open',
      description: 'Track when a user first opens the app',
      icon: CursorArrowRaysIcon,
      template: {
        name: 'first_open',
        description: 'Track when a user first opens the app',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: 'regex_contains',
                    value: 'first_open',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'count',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Example Email Subscription',
      description: 'Track an example email subscription with a custom event. (once per session)',
      icon: EnvelopeIcon,
      template: {
        name: 'example_email_subscription',
        description: 'Track an example email subscription',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: 'regex_contains',
                    value: 'email_subscription',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'max',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Example Email Subscription with GA4 enhanced measurement',
      description:
        'Track an example email subscription using the GA4 enhanced measurement. (once per session)',
      icon: EnvelopeIcon,
      template: {
        name: 'example_email_subscription',
        description: 'Track an example email subscription',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: 'regex_contains',
                    value: 'email_subscription',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'max',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Sales PDF Downloads Per Session',
      description: 'Adds a session property for counting sales PDF downloads (once per session)',
      schema_template: 'string_number',
      icon: DocumentArrowDownIcon,
      template: {
        name: 'sales_pdf_downloads_per_session',
        attribution_table: 'include',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: 'regex_contains',
                    value: 'file_download',
                  },
                  {
                    col: 'dim__file_name',
                    operator: 'regex_contains',
                    value: '(?i)Sales PDF',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'max',
              },
            },
          ],
        },
      },
    },
  ];

  const handleAddConversion = useCallback(
    (template) => {
      setIsUpdating(true);
      const updatedTemplate = { ...template };

      const getUniqueEventName = (baseName, existingEvents) => {
        let eventNumber = 0;
        let newName = baseName;

        const isNameTaken = (name) => existingEvents.some((event) => event.name === name);

        while (isNameTaken(newName)) {
          eventNumber += 1;
          newName = `${baseName}${eventNumber}`;
        }

        return newName;
      };

      updatedTemplate.name = getUniqueEventName(updatedTemplate.name, currentConversionEvents);

      updateDbtProjectFileListProperty({
        id,
        property_name: 'conversion_events',
        operation: 'add',
        item: updatedTemplate,
      })
        .unwrap()
        .then(() => {
          setConversionEvents([...conversionEvents, updatedTemplate]);
        })
        .catch((error) => {
          console.error('Failed to add conversion:', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [currentConversionEvents, updateDbtProjectFileListProperty, id]
  );

  const handleDeleteConversion = useCallback(
    (indexToDelete) => {
      setIsUpdating(true);
      updateDbtProjectFileListProperty({
        id,
        property_name: 'conversion_events',
        operation: 'delete',
        index: indexToDelete,
      })
        .unwrap()
        .then(() => {
          setConversionEvents(conversionEvents.filter((_, index) => index !== indexToDelete));
        })
        .catch((error) => {
          console.error('Failed to delete conversion:', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [conversionEvents, updateDbtProjectFileListProperty, id]
  );

  const emptyStateActions = conversionTemplates.map(({ title, description, icon, template }) => ({
    title,
    description,
    icon,
    action: () => handleAddConversion(template),
  }));

  return {
    emptyStateActions,
    handleAddConversion,
    handleDeleteConversion,
    isUpdating,
  };
};

export default useGA4ConversionTemplates;
