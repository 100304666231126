export const PRICING_TIERS_DEV = [
  // {
  //   name: 'Free',
  //   description: 'Try out a pipeline if you have less than 2 months data.',
  //   id: 'tier-free',
  //   href: '#',
  //   priceDisplay: {
  //     month: 'Default§',
  //     year: 'Default',
  //   },
  //   mostPopular: false,
  // },
  {
    name: 'Do It Yourself',
    description: 'Perfect for agencies or businesses who want to self serve.',
    id: 'tier-basic',
    href: '#',
    priceDisplay: {
      month: '£150',
      year: '£1500',
    },
    mostPopular: false,
    priceId: {
      month: 'pri_01j5z8vtdv0mpa3qzhyrtyywcv',
      year: 'pri_01j5z8xsmnsa1mdchhd7be3q0w',
    },
    addons: [
      {
        name: 'Add Pipeline & Events',
        id: 'additional-property',
        description: 'Add 1 pipeline & increase daily event limit 500k.',
        priceId: {
          month: 'pri_01jbcrynhv7p2bav06rkwtqc5g',
          year: 'pri_01jcycnf36ae776cfs2ens6110',
        },
        priceDisplay: {
          month: '£30',
          year: '£300',
        },
      },
    ],
    slider: {
      addon_id: 'additional-property',
      min: 0,
      max: 10,
      default: 0,
      features: [
        {
          id: 'pipelines',
          increment: 1,
          formatter: (value) => `${value}`,
        },
        {
          id: 'max-daily-events',
          increment: 500000,
          formatter: (value) => `${(value / 1000000).toFixed(1)} million`,
        },
      ],
    },
  },
  {
    name: 'Core',
    description: 'Businesses who want the most from GA4 without a data team.',
    id: 'tier-core',
    href: '#',
    priceDisplay: {
      month: '$29',
      year: '$290',
    },
    mostPopular: true,
    priceId: {
      month: 'pri_01j4ppg56ygqfjpmt880dnksms',
      year: 'pri_01j4ppkrafpv6g4tan5hkqs695',
    },
  },
  {
    name: 'Custom',
    description: 'Enterprise-grade solutions tailored to your needs.',
    id: 'tier-custom',
    href: '#',
    priceDisplay: {
      month: 'Contact Us',
      year: 'Contact Us',
    },
    mostPopular: false,
  },
];

export const PRICING_TIERS_PROD = [
  {
    name: 'Basic',
    description: 'Perfect for agencies or businesses who want to self serve.',
    id: 'tier-basic',
    href: '#',
    priceDisplay: {
      month: '£150',
      year: '£1500',
    },
    mostPopular: false,
    priceId: {
      month: 'pri_01jgc9c0e6679bdbtn5c9kxaqj',
      year: 'pri_01jgc9b0xcf6e0z20e9f9vnn5e',
    },
    addons: [
      {
        name: 'Additional Property',
        id: 'additional-property',
        description: 'Add 1 pipeline & increase daily event limit 500k.',
        priceId: {
          month: 'pri_01jgc8qshjfhpr3vcz367d58j5',
          year: 'pri_01jgc8pq0ewj2ewjxkf9128h91',
        },
      },
    ],
    slider: {
      addon_id: 'additional-property',
      min: 0,
      max: 10,
      default: 0,
      features: [
        {
          id: 'pipelines',
          increment: 1,
          formatter: (value) => `${value}*`,
        },
        {
          id: 'max-daily-events',
          increment: 500000,
          formatter: (value) => `${(value / 1000000).toFixed(1)} million*`,
        },
      ],
    },
  },
  {
    name: 'Core',
    description: 'Businesses who want the most from GA4 without a data team.',
    id: 'tier-core',
    href: '#',
    priceDisplay: {
      month: '£600',
      year: '£6000',
    },
    mostPopular: true,
    priceId: {
      month: 'pri_01jgc9hqr70eyth5d1r47vmybd',
      year: 'pri_01jgc9g3y1kptaapst07gz9mvk',
    },
  },
  {
    name: 'Custom',
    description: 'Enterprise-grade solutions tailored to your needs',
    id: 'tier-custom',
    href: '#',
    priceDisplay: {
      month: 'Contact Us',
      year: 'Contact Us',
    },
    mostPopular: false,
  },
];

// Features
export const FEATURE_SECTIONS = [
  {
    name: 'Usage',
    features: [
      {
        id: 'users',
        name: 'Users',
        tiers: {
          'tier-basic': 'Unlimited',
          'tier-core': 'Unlimited',
          'tier-custom': 'Unlimited',
        },
      },
      {
        id: 'pipelines',
        name: 'Pipelines',
        tiers: { 'tier-basic': '5', 'tier-core': '1', 'tier-custom': 'Unlimited' },
      },
      {
        id: 'max-daily-events',
        name: 'Max daily event volume',
        tiers: { 'tier-basic': '1000000', 'tier-core': '5 million', 'tier-custom': 'Unlimited' },
      },
    ],
  },
  {
    name: 'Features',
    features: [
      {
        id: 'reporting-templates',
        name: 'Reporting Templates',
        tiers: { 'tier-basic': true, 'tier-core': true, 'tier-custom': true },
      },
      {
        id: 'external-warehouse-export',
        name: 'External warehouse export',
        tiers: { 'tier-basic': false, 'tier-core': true, 'tier-custom': true },
      },
      {
        id: 'dedicated-slack-channel',
        name: 'Dedicated Slack Channel',
        tiers: {
          'tier-basic': false,
          'tier-core': true,
          'tier-custom': true,
        },
      },
      {
        id: 'customer-success-engineer',
        name: 'Customer Success Engineer',
        tiers: {
          'tier-basic': false,
          'tier-core': true,
          'tier-custom': true,
        },
      },
      {
        id: 'setup',
        name: 'Setup',
        tiers: { 'tier-basic': false, 'tier-core': true, 'tier-custom': true },
      },
      {
        id: 'custom-reports',
        name: 'Custom reports',
        tiers: { 'tier-basic': false, 'tier-core': false, 'tier-custom': true },
      },
      {
        id: 'custom-pipelines',
        name: 'Custom pipelines',
        tiers: { 'tier-basic': false, 'tier-core': false, 'tier-custom': true },
      },
    ],
  },
];

export const COUNTRIES = [
  { code: 'US', name: 'United States' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'CA', name: 'Canada' },
  // Add more countries as needed
];
