/**
 * Cleans Django Rest Framework error messages that come in array format
 * @param {string|array} errorMessage - The error message from the API
 * @param {string} defaultMessage - Fallback message if error is empty/invalid
 * @returns {string} Cleaned error message
 */
export const cleanDRFError = (errorMessage, defaultMessage = 'An error occurred') => {
  if (!errorMessage) return defaultMessage;

  // If it's already a string and not array-like, return it
  if (typeof errorMessage === 'string' && !errorMessage.startsWith('[')) {
    return errorMessage;
  }

  // Handle array-like string from DRF
  if (
    typeof errorMessage === 'string' &&
    errorMessage.startsWith('[') &&
    errorMessage.endsWith(']')
  ) {
    try {
      const parsed = JSON.parse(errorMessage.replace(/'/g, '"'));
      return Array.isArray(parsed) ? parsed[0] : errorMessage;
    } catch {
      return errorMessage;
    }
  }

  // Handle actual arrays
  if (Array.isArray(errorMessage)) {
    return errorMessage[0] || defaultMessage;
  }

  return errorMessage;
};
