import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useGA4SessionsSchema from 'pages/models/source_ga4_bigquery/FormPageAttribution/hooks/useGA4SessionsSchema';
import {
  fieldInfoSourceGA4DBTAttribution,
  sourceGA4BigQueryAttributionSchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageAttribution/schema/sourceGA4DBTAttributionSchema';
import {
  useAddSourceGA4BigQueryMutation,
  useGetDbtProjectFileQuery,
  usePartialUpdateDbtProjectFileMutation,
} from 'services/rtkApi';

const SourceGA4BigQueryFormAttribution = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const navigate = useNavigate();
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  // Get schema data for dropdowns
  const { metricOptions, dimensionOptions, isLoading, loadingForeignKeys } =
    useGA4SessionsSchema(pipelineId);

  // Get existing data for the form.
  // Get existing data for the form.
  const mutationHook = isUpdate
    ? usePartialUpdateDbtProjectFileMutation
    : useAddSourceGA4BigQueryMutation;
  const queryHook = isUpdate ? useGetDbtProjectFileQuery : undefined;

  const preSubmit = React.useCallback(
    (formData) => {
      return {
        ...formData,
      };
    },
    [pipelineId]
  );

  const foreignKeyOptions = React.useMemo(
    () => [
      {
        key: ['attribution_dimensions'],
        options: dimensionOptions,
      },
      {
        key: ['attribution_metrics', '*', 'name'],
        options: metricOptions,
      },
    ],
    [dimensionOptions, metricOptions]
  );

  if (isLoading || loadingForeignKeys.length > 0) {
    return <LoadingSpinner />;
  }

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={sourceGA4BigQueryAttributionSchemaForm}
      fieldInfo={fieldInfoSourceGA4DBTAttribution}
      warnOnUnsavedChanges
      preSubmit={preSubmit}
      foreignKeyOptions={foreignKeyOptions}
      loadingForeignKeys={loadingForeignKeys}
    />
  );
};

export default SourceGA4BigQueryFormAttribution;
