import { useMemo } from 'react';

import { useGetBigQueryTableSchemaQuery, useGetPipelineByIdQuery } from 'services/rtkApi';

/**
 * Hook to fetch and process GA4 sessions schema into metrics and dimensions
 * @param {string} pipelineId - The ID of the pipeline
 * @returns {Object} Object containing metrics and dimensions options, loading state, and loading foreign keys
 */
const useGA4SessionsSchema = (pipelineId) => {
  const { data: pipelineData, isLoading: isLoadingPipeline } = useGetPipelineByIdQuery(pipelineId, {
    skip: !pipelineId,
  });

  const { data: tableSchema, isLoading: isLoadingSchema } = useGetBigQueryTableSchemaQuery(
    {
      project_id: pipelineData?.gcloud_project_id,
      dataset_id: pipelineData?.bq_output_dataset,
      table_id: 'ga4_mrt_sessions',
    },
    {
      skip: !pipelineData,
    }
  );

  const { metricOptions, dimensionOptions } = useMemo(() => {
    if (!tableSchema) {
      return { metricOptions: [], dimensionOptions: [] };
    }

    const metrics = tableSchema
      .filter((field) => field.name.startsWith('met_'))
      .map((field) => ({
        label: field.name,
        value: field.name,
      }));

    const dimensions = tableSchema
      .filter((field) => field.name.startsWith('dim_'))
      .map((field) => ({
        label: field.name,
        value: field.name,
      }));

    return {
      metricOptions: metrics,
      dimensionOptions: dimensions,
    };
  }, [tableSchema]);

  const isLoading = useMemo(
    () => isLoadingPipeline || isLoadingSchema,
    [isLoadingPipeline, isLoadingSchema]
  );

  const loadingForeignKeys = useMemo(
    () => (isLoading ? [['attribution_dimensions'], ['attribution_metrics', '*', 'name']] : []),
    [isLoading]
  );

  return {
    metricOptions,
    dimensionOptions,
    isLoading,
    pipelineData,
    loadingForeignKeys,
  };
};

export default useGA4SessionsSchema;
