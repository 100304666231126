import Alert from 'components/Alert/Alert';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import {
  useGetAccountEventTotalsQuery,
  useGetPaddleActivePlansQuery,
  useGetPipelinesQuery,
} from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * Progress bar component to show usage metrics
 */
const UsageProgressBar = ({ used, total, label, description }) => {
  const percentage = Math.min((used / total) * 100, 100);

  return (
    <div className="mb-8">
      <div className="flex justify-between mb-2">
        <div>
          <p className="text-sm font-medium text-gray-900">{label}</p>
          {description && <p className="text-sm text-gray-500">{description}</p>}
        </div>
        <p className="text-sm font-medium text-gray-900">
          {used.toLocaleString()} / {total.toLocaleString()}
        </p>
      </div>
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          style={{ width: `${percentage}%` }}
          className={`h-2 rounded-full ${percentage >= 90 ? 'bg-red-600' : 'bg-indigo-600'}`}
        />
      </div>
    </div>
  );
};

/**
 * Page component showing account usage metrics
 */
const AccountUsagePage = () => {
  const {
    data: activePlans,
    isLoading: plansLoading,
    error: plansError,
  } = useGetPaddleActivePlansQuery();

  const {
    data: eventTotals,
    isLoading: eventsLoading,
    error: eventsError,
  } = useGetAccountEventTotalsQuery();

  const {
    data: pipelines,
    isLoading: pipelinesLoading,
    error: pipelinesError,
  } = useGetPipelinesQuery({ no_pagination: true });

  // Calculate total limits across all active plans
  const getTotals = () => {
    if (!activePlans) return { events: 0, pipelines: 0 };

    return activePlans.reduce(
      (acc, plan) => ({
        events: acc.events + plan.quantity_events,
        pipelines: acc.pipelines + plan.quantity_pipelines,
      }),
      { events: 0, pipelines: 0 }
    );
  };

  const limits = getTotals();
  const isLoading = plansLoading || eventsLoading || pipelinesLoading;

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>

        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Usage Overview</h1>
        </PageLayoutInnerStrip>

        <PageLayoutInner>
          {isLoading ? (
            <LoadingSpinner text="Loading usage data..." isFullWidth />
          ) : (
            <div className="max-w-3xl mt-6">
              {(plansError || eventsError || pipelinesError) && (
                <Alert
                  status="error"
                  message={
                    plansError
                      ? 'Failed to load subscription data. Please try again later.'
                      : eventsError
                      ? 'Failed to load usage data. Please try again later.'
                      : 'Failed to load pipelines data. Please try again later.'
                  }
                  className="mb-4"
                />
              )}

              {!plansError && !eventsError && !pipelinesError && (
                <>
                  <UsageProgressBar
                    used={eventTotals?.current || 0}
                    total={limits.events}
                    label="Daily Active Events"
                    description="7-day running average of daily events"
                  />

                  <UsageProgressBar
                    used={pipelines?.length || 0}
                    total={limits.pipelines}
                    label="Active Pipelines"
                  />
                </>
              )}
            </div>
          )}
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default AccountUsagePage;
