// authApi.js
import axios from 'axios';
import cookie from 'cookie';

const authApi = axios.create();

// Function to get current state - will be set later to avoid circular dependency
let getState = () => ({});

// We export a function to set the getState function after store is created
export const setStateAccessor = (stateAccessor) => {
  getState = stateAccessor;
};

// Request interceptor for CSRF token and account override
authApi.interceptors.request.use((config) => {
  // Add CSRF token
  const { csrftoken } = cookie.parse(document.cookie);
  if (csrftoken) {
    config.headers['X-CSRFTOKEN'] = csrftoken;
  }

  // Add account override from Redux state if present
  // This ensures all direct API calls respect the tenant switching functionality
  // and use the correct tenant in the backend
  const state = getState();
  if (state?.auth?.accountOverride) {
    config.headers['X-Account-Override'] = state.auth.accountOverride;
  }

  return config;
});

export const setAuthToken = (token, tokenType = 'JWT') => {
  if (token) {
    authApi.defaults.headers.common.Authorization = `${tokenType} ${token}`;
  } else {
    delete authApi.defaults.headers.common.Authorization;
  }
};

export default authApi;
