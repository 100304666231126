import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import useSourceGA4BigQueryDynamicOptions from 'pages/models/source_ga4_bigquery/_hooks/useSourceGA4BigQueryDynamicOptions';
import { useUpdateDbtProjectFileListPropertyMutation } from 'services/rtkApi';

import useConvertPropertyValueToCorrectDataType from '../../_hooks/useConvertPropertyValueToCorrectDataType';
import useSourceGA4BigQueryDynamicFields from '../../_hooks/useSourceGA4BigQueryDynamicFieldsNew';

import {
  conditionColTypePath,
  conditionOperatorBasePath,
} from './SourceGA4PathsSessionPropertyConstantsNew';

/**
 * Dynamic fields config for dependent dropdowns. When the schema value changes then
 * we change the options of the dependent fields.
 */
const dynamicFieldOptionsConfig = [
  {
    field: conditionColTypePath,
    dependentField: conditionOperatorBasePath,
    options: [
      {
        value: 'STRING',
        dependentOptions: [
          { label: 'Equals', value: '=' },
          { label: 'Contains', value: 'contains' },
          { label: 'Regex contains', value: 'regex_contains' },
          { label: 'Is Empty', value: 'is_empty' },
        ],
      },
      {
        value: 'INTEGER',
        dependentOptions: [
          { label: 'Equals', value: '=' },
          { label: 'Greater than', value: '>' },
          { label: 'Greater than or equal to', value: '>=' },
          { label: 'Less than', value: '<' },
          { label: 'Less than or equal to', value: '<=' },
        ],
      },
    ],
  },
  // Add more configurations for other dynamic fields here
];

/**
 * Component for handling a single derived session property form.
 */
const SourceGA4BigQueryFormSingleSessionProperty = ({
  id,
  tableSchema,
  selectedPropertyIndex,
  allProperties,
  schema,
  fieldInfo,
  foreignKeyOptions,
  loadingForeignKeys,
}) => {
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  /**
   * SETUP CUSTOM SUBMIT HOOK
   * We specifically have a hook to update items within a property
   * because otherwise I've found that component rerenders where we have to send
   * the entire property can overwrite and delete everything.
   */
  const [updateDbtProjectFileListProperty, updateDbtProjectFileListPropertyResult] =
    useUpdateDbtProjectFileListPropertyMutation();

  const wrappedMutationHook = useCallback(() => {
    const wrappedMutation = async (formData) => {
      if (!allProperties || allProperties.length === 0) {
        throw new Error('No properties available');
      }

      const operation = isUpdate ? 'update' : 'add';
      const payload = {
        id,
        property_name: 'derived_session_properties',
        operation,
        item: formData,
      };

      if (isUpdate) {
        payload.index = selectedPropertyIndex;
      }

      return updateDbtProjectFileListProperty(payload);
    };

    return [wrappedMutation, updateDbtProjectFileListPropertyResult];
  }, [
    allProperties,
    updateDbtProjectFileListProperty,
    updateDbtProjectFileListPropertyResult,
    id,
    selectedPropertyIndex,
    isUpdate,
  ]);
  const mutationHook = wrappedMutationHook;

  /**
   * BASE CRUD FORM SETUP
   * Basic react hook form setup.
   */
  const formRef = useRef(null);
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  /**
   * Fake query hook that returns the selectedProperty data
   */
  const fakeQueryHook = useCallback(
    () => ({
      data: isUpdate ? allProperties[selectedPropertyIndex] : null,
      isSuccess: true,
    }),
    [allProperties, selectedPropertyIndex, isUpdate]
  );

  /**
   * SET FIELDS ON CHANGE
   * Dynamic fields setter. When someone sets a field, we need to set another field.
   *
   * This is used to set the type of the field based on the table schema.
   */
  const { postOnChange } = useSourceGA4BigQueryDynamicFields(tableSchema);

  /**
   * SET OPTIONS ON CHANGE
   * Dynamic select dropdown options using the foreign key options hook.
   */
  const { postOnChangeOptions } = useSourceGA4BigQueryDynamicOptions(
    foreignKeyOptions,
    dynamicFieldOptionsConfig
  );

  /**
   * VALIDATE AND CONVERT VALUES
   * Convert values to correct types based on schema and check for duplicates
   */
  const convertValues = useConvertPropertyValueToCorrectDataType(tableSchema);
  const { setError } = methods;

  const preSubmit = async (data) => {
    if (!allProperties || allProperties.length === 0) return data;

    const existingNames = allProperties
      .filter((_, index) => index !== selectedPropertyIndex)
      .map((prop) => prop.name);

    if (existingNames.includes(data.name)) {
      setError('name', {
        type: 'manual',
        message: 'This name already exists. Please choose a unique name.',
      });
      throw new Error('Validation failed');
    }

    // Convert values and check for type errors
    const { data: convertedData, errors } = convertValues(data);

    if (errors.length > 0) {
      // Set error on the first field with an issue
      const firstError = errors[0];
      setError('propertyConditionBlock.propertyConditions', {
        type: 'manual',
        message: firstError.message,
      });
      throw new Error('Type validation failed');
    }

    return convertedData;
  };

  return (
    <FormProvider {...methods}>
      <CrudForm
        ref={formRef}
        entityId={id}
        key={`pipeline_${formType}`}
        formType={formType}
        mutationHook={mutationHook}
        queryHook={fakeQueryHook}
        schema={schema}
        fieldInfo={fieldInfo}
        warnOnUnsavedChanges
        postOnChange={postOnChange}
        preSubmit={preSubmit}
        postOnChangeOptions={postOnChangeOptions}
        foreignKeyOptions={foreignKeyOptions}
        loadingForeignKeys={loadingForeignKeys}
        partialFileUpdate
      />
    </FormProvider>
  );
};

SourceGA4BigQueryFormSingleSessionProperty.propTypes = {
  id: PropTypes.string,
  tableSchema: PropTypes.object,
  selectedPropertyIndex: PropTypes.number,
  allProperties: PropTypes.arrayOf(PropTypes.object),
  schema: PropTypes.object.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  foreignKeyOptions: PropTypes.object.isRequired,
  loadingForeignKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SourceGA4BigQueryFormSingleSessionProperty;
