import { useNavigate } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { PIPELINE_RUN_VIEW } from 'routes/constants';

import AccountsTable from './components/AccountsTable';
import PipelineRunsTable from './components/PipelineRunsTable';
import PipelineStatsCard from './components/PipelineStatsCard';
import { useAdminDashboardData } from './hooks/useAdminDashboardData';

/**
 * Admin dashboard page showing overall system statistics
 */
const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const { accountsWithPipelines, pipelineStats, pipelineRuns, isLoading } = useAdminDashboardData();

  if (isLoading) return <LoadingSpinner text="Loading dashboard data..." />;

  const handleRunClick = (runId) => {
    navigate(`${PIPELINE_RUN_VIEW.replace(':id', runId)}`);
  };

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Admin Dashboard</h1>

      {/* Pipeline Statistics */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Pipeline Statistics</h2>
        <PipelineStatsCard stats={pipelineStats} />
      </div>

      {/* Latest Accounts */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Latest Accounts</h2>
        <AccountsTable accounts={accountsWithPipelines} />
      </div>

      {/* Yesterday's Pipeline Runs */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Yesterday&apos;s Pipeline Runs</h2>
        <PipelineRunsTable runs={pipelineRuns} onRunClick={handleRunClick} />
      </div>
    </div>
  );
};

export default AdminDashboardPage;
