import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import FormPropertyList from 'components/Forms/FormPropertyList/FormPropertyList';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useConvertCustomPropertiesValuesToString from 'pages/models/source_ga4_bigquery/_hooks/useConvertCustomPropertiesValuesToString';
import useGA4TableSchema from 'pages/models/source_ga4_bigquery/_hooks/useGA4TableSchema';
import useSourceGA4BigQuerySchemaOptions from 'pages/models/source_ga4_bigquery/_hooks/useSourceGA4BigQuerySchemaOptions';
import { useGetDbtProjectFileQuery } from 'services/rtkApi';

import useGA4ConversionTemplates, { emptyConversion } from '../hooks/useGA4ConversionTemplates';

import SourceGA4BigQueryFormSingleConversionsNew from './SourceGA4BigQueryFormSingleConversionsNew';

/**
 * SourceGA4BigQueryFormAllConversions component for managing all conversions.
 */
const SourceGA4BigQueryFormAllConversions = () => {
  const { id, pipelineId } = useParams();

  // Go and get table schema for the form.
  const {
    pipelineData,
    tableSchema,
    isLoading: isLoadingTableSchema,
  } = useGA4TableSchema(pipelineId, 'ga4_base_events');

  // Get foreign key options at the top level
  const { foreignKeyOptions, loadingForeignKeys } = useSourceGA4BigQuerySchemaOptions(
    tableSchema,
    isLoadingTableSchema
  );

  // Get the form data
  const { data: formData, isLoading: isLoadingForm, error } = useGetDbtProjectFileQuery(id);

  // Extract conversion_events and default to an empty array
  const raw_conversion_events = formData?.conversion_events || [];

  // Because we don't know if we're expecting a string or number, the validation seems to struggle with
  // it. So we convert all to strings. Then when we submit intelligently convert back.
  const conversion_events = useConvertCustomPropertiesValuesToString(raw_conversion_events);

  /**
   * Get actions and state with the actions.
   */
  const { emptyStateActions, handleAddConversion, handleDeleteConversion, isUpdating } =
    useGA4ConversionTemplates(id, conversion_events, formData?.conversion_events || []);

  // Update items to include the index
  const items = useMemo(() => {
    return conversion_events.map((prop, index) => ({
      id: index,
      name: prop.name,
      index,
    }));
  }, [conversion_events]);

  const bottomActions = [
    {
      text: 'Add conversion event',
      description: "Add a conversion event like 'purchase completed' or 'form submitted'",
      action: () => handleAddConversion({ ...emptyConversion }),
    },
  ];

  if (isLoadingForm || isUpdating || isLoadingTableSchema || loadingForeignKeys.length > 0) {
    return <LoadingSpinner text="Loading conversions..." />;
  }
  if (error) return <p>Error loading data</p>;

  return (
    <FormPropertyList
      items={items}
      emptyStateTitle="Add example conversion template"
      emptyStateDescription="Add a conversion event to get started."
      emptyStateActions={emptyStateActions}
      emptyProjectText="Add custom conversion"
      emptyProjectAction={() => handleAddConversion({ ...emptyConversion })}
      onDeleteItem={handleDeleteConversion}
      bottomActions={bottomActions}
      isLoading={isUpdating}
    >
      {(selectedItem) => (
        <SourceGA4BigQueryFormSingleConversionsNew
          id={id}
          pipelineData={pipelineData}
          tableSchema={tableSchema}
          isLoadingTableSchema={isLoadingTableSchema}
          selectedPropertyIndex={selectedItem.index}
          allProperties={conversion_events}
          foreignKeyOptions={foreignKeyOptions}
          loadingForeignKeys={loadingForeignKeys}
        />
      )}
    </FormPropertyList>
  );
};

export default SourceGA4BigQueryFormAllConversions;
