/**
 * Slider component for adjusting addon quantities in pricing tiers
 * @param {Object} props
 * @param {Object} props.tier - Pricing tier containing slider config
 * @param {number} props.quantity - Current quantity value
 * @param {Function} props.onQuantityChange - Callback when quantity changes
 */
export function AddonSlider({ tier, quantity, onQuantityChange }) {
  // Find the matching addon using the addon_id from slider config
  const addon = tier.addons?.find((addon) => addon.id === tier.slider.addon_id);

  return (
    <div>
      <input
        type="range"
        min={tier.slider.min}
        max={tier.slider.max}
        value={quantity}
        onChange={(e) => onQuantityChange(parseInt(e.target.value, 10))}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
      />
      <div className="text-sm text-gray-500 mt-1">{addon?.name || tier.slider.name}</div>
    </div>
  );
}
