import useBypassTenant from 'hooks/tenant/useBypassTenant';

/**
 * Toggle component for staff/admin users to manually bypass tenant settings
 *
 * This component only renders for staff/superusers and allows them to
 * toggle tenant bypassing on and off.
 *
 * @param {Object} props - Component props
 * @param {string} props.className - Additional CSS classes
 */
const TenantBypassToggle = ({ className = '' }) => {
  const { isEnabled, toggleBypass, canBypass } = useBypassTenant();

  // Only show for admin users
  if (!canBypass) return null;

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <span className="text-sm text-gray-700">Bypass Tenant</span>
      <button
        type="button"
        onClick={toggleBypass}
        className={`relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-deep-sea-green-500 focus:ring-offset-2 ${
          isEnabled ? 'bg-deep-sea-green-600' : 'bg-gray-200'
        }`}
        role="switch"
        aria-checked={isEnabled}
      >
        <span
          aria-hidden="true"
          className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
            isEnabled ? 'translate-x-5' : 'translate-x-0'
          }`}
        />
      </button>
      {isEnabled && <span className="text-xs text-red-600">active</span>}
    </div>
  );
};

export default TenantBypassToggle;
