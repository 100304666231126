import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import LinkCustom from 'components/LinkCustom';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ModelDisplay from 'components/ModelDisplay/ModelDisplay';
import { fieldInfoDisplayPipeline } from 'pages/models/pipelines/schema/pipelineSchema';
import { useGetPipelineByIdQuery } from 'services/rtkApi';

/**
 * Displays pipeline data fetched from the API
 * @param {Object} props
 * @param {Function} props.onSuccess - Callback function on successful data fetch
 */
const PipelineModelDisplay = ({ onSuccess }) => {
  const { id } = useParams();
  const { data: pipeline, isLoading, isError } = useGetPipelineByIdQuery(id);

  React.useEffect(() => {
    if (pipeline && !isLoading && !isError) {
      onSuccess?.(pipeline);
    }
  }, [pipeline, isLoading, isError, onSuccess]);

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <p>Error loading pipeline data</p>;

  const formatters = [
    ['created_at', (date) => new Date(date).toLocaleString()],
    ['updated_at', (date) => new Date(date).toLocaleString()],
    ['run_hour', (hour) => (hour !== null ? `${hour.toString().padStart(2, '0')}:00` : null)],
    [
      'github_repo',
      (repo) =>
        repo ? (
          <LinkCustom href={repo.startsWith('http') ? repo : `https://github.com/${repo}`}>
            {repo}
          </LinkCustom>
        ) : null,
    ],
  ];

  // Filter out unwanted fields from data
  const filteredData = pipeline
    ? Object.fromEntries(
        Object.entries(pipeline).filter(
          ([key]) => !['id', 'account', 'archived', 'archived_at'].includes(key)
        )
      )
    : null;

  const dataCustomCols = [
    {
      fields: ['description', 'notes'],
      cols: 1, // These fields will span full width
    },
  ];

  return filteredData ? (
    <ModelDisplay
      data={filteredData}
      fieldNames={fieldInfoDisplayPipeline}
      formatters={formatters}
      numCols={2} // Default 2 columns for most fields
      dataCustomCols={dataCustomCols} // Custom layout for specific fields
    />
  ) : null;
};

PipelineModelDisplay.propTypes = {
  onSuccess: PropTypes.func,
};

export default PipelineModelDisplay;
