import PropTypes from 'prop-types';

/**
 * A simple boolean input component (checkbox) for use outside of react-hook-form
 * @param {Object} props - Component props
 * @param {string} props.id - Input ID
 * @param {string} props.label - Input label
 * @param {boolean} props.value - Current value
 * @param {function} props.onChange - Change handler
 * @param {boolean} props.disabled - Whether the input is disabled
 * @param {string} props.className - Additional CSS classes
 */
const FormBooleanNonHookForm = ({ id, label, value, onChange, disabled, className }) => (
  <div className={`flex items-center space-x-2 ${className}`}>
    <input
      id={id}
      type="checkbox"
      checked={value}
      onChange={(e) => onChange(e.target.checked)}
      disabled={disabled}
      className="h-4 w-4 rounded border-gray-300 text-deep-sea-green-600 focus:ring-deep-sea-green-500"
    />
    <label htmlFor={id} className="text-sm text-gray-700">
      {label}
    </label>
  </div>
);

FormBooleanNonHookForm.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

FormBooleanNonHookForm.defaultProps = {
  disabled: false,
  className: '',
};

export default FormBooleanNonHookForm;
