import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useState } from 'react';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import { AddonSlider } from './AddonSlider';

/**
 * Shared display component for pricing tables
 */
export function PricingTableDisplay({
  localTiers,
  billingCycle,
  setBillingCycle,
  sections,
  isLoading,
  handleAction,
  getButtonText,
  utils: { getBillingCycleText, getTextSizeClass, classNames },
  buttonClassName,
  isUpgrade = false,
  addonQuantities,
  onAddonQuantityChange,
  calculateTotalPrice,
  currency,
  currencySymbol,
}) {
  const [modifiedTiers, setModifiedTiers] = useState([]);

  useEffect(() => {
    if (localTiers?.length) {
      setModifiedTiers(
        localTiers.map((tier) => ({
          ...tier,
          originalPriceDisplay: { ...tier.priceDisplay },
          features: { ...tier.features },
        }))
      );
    }
  }, [localTiers]);

  const handleSliderChange = (tierId, addonId, value) => {
    onAddonQuantityChange(tierId, addonId, value);
  };

  const formatFeatureValue = (tier, feature) => {
    // Find matching slider feature config if it exists
    const sliderFeature = tier.slider?.features.find((f) => f.id === feature.id);

    if (sliderFeature?.formatter) {
      const baseValue = parseInt(feature.tiers[tier.id].replace(/[^0-9]/g, '')) || 0;
      const addonQuantity = addonQuantities[tier.id]?.[tier.slider.addon_id] || 0;
      // Use the increment from the slider feature config
      const incrementedValue = baseValue + sliderFeature.increment * addonQuantity;
      return sliderFeature.formatter(incrementedValue);
    }

    return feature.tiers[tier.id];
  };

  if (isLoading) {
    return (
      <div className="mt-4">
        <LoadingSpinner text="Loading plans" isFullWidth />
      </div>
    );
  }

  const getButtonProps = (tier) => {
    const buttonText = getButtonText ? getButtonText(tier, billingCycle) : 'Buy plan';
    const isCurrentPlan = buttonText === 'Current Plan';

    const getButtonStyle = () => {
      if (isCurrentPlan) {
        return 'bg-gray-100 text-gray-400 cursor-not-allowed';
      }
      if (isUpgrade) {
        return 'text-red-damask-600 ring-1 ring-inset ring-red-damask-200 hover:ring-red-damask-300';
      }
      return tier.mostPopular
        ? 'bg-red-damask-600 text-white hover:bg-red-damask-500'
        : 'text-red-damask-600 ring-1 ring-inset ring-red-damask-200 hover:ring-red-damask-300';
    };

    return {
      onClick: () => !isCurrentPlan && handleAction(tier, billingCycle),
      disabled: isCurrentPlan,
      className: classNames(
        getButtonStyle(),
        'mt-8 w-full block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-damask-600'
      ),
      children: buttonText,
    };
  };

  const renderPriceSection = (tier) => {
    const displayPrice = calculateTotalPrice(tier, billingCycle);
    const isAnnual = billingCycle === 'year';
    const monthlyPrice = isAnnual ? displayPrice / 12 : displayPrice;

    return (
      <>
        <div className="flex flex-col items-baseline gap-x-1 text-gray-900 h-[50px]">
          <div className="flex items-baseline">
            <span className={getTextSizeClass(displayPrice)}>
              {displayPrice === 'Contact Us' ? (
                'Contact Us'
              ) : (
                <>
                  {currencySymbol}
                  {isAnnual ? monthlyPrice : displayPrice}
                </>
              )}
            </span>
            {displayPrice !== 'Contact Us' && <span className="text-sm font-semibold">/month</span>}
          </div>
        </div>
        {tier.slider && (
          <AddonSlider
            tier={tier}
            quantity={addonQuantities[tier.id]?.[tier.slider.addon_id] || 0}
            onQuantityChange={(value) => handleSliderChange(tier.id, tier.slider.addon_id, value)}
          />
        )}
      </>
    );
  };

  return (
    <div className="">
      {/* Billing cycle toggle */}
      <div className="flex justify-center mb-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="relative mt-6 flex self-center rounded-lg bg-gray-100 p-0.5 sm:mt-8">
              <button
                type="button"
                className={`relative w-1/2 whitespace-nowrap rounded-md py-2 text-sm font-medium text-gray-900 focus:z-10 focus:outline-none sm:w-auto sm:px-8 ${
                  billingCycle === 'month' ? 'bg-white shadow-sm' : ''
                }`}
                onClick={() => setBillingCycle('month')}
              >
                Monthly billing
              </button>
              <button
                type="button"
                className={`relative ml-0.5 w-1/2 whitespace-nowrap rounded-md py-2 text-sm font-medium text-gray-900 focus:z-10 focus:outline-none sm:w-auto sm:px-8 ${
                  billingCycle === 'year' ? 'bg-white shadow-sm' : ''
                }`}
                onClick={() => setBillingCycle('year')}
              >
                <div>
                  Yearly billing
                  <span className="block text-xs text-gray-500">(save 16%)</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {!isUpgrade && (
        <>
          <div className="bg-white shadow sm:rounded-lg p-4 pb-2 mb-4 mx-auto max-w-7xl">
            <p className="text-gray-500 font-xs text-center">
              There is a 14 day free trial for both our basic and core plans.
            </p>
          </div>
          {addonQuantities &&
            Object.values(addonQuantities).some((addon) =>
              Object.values(addon).some((quantity) => quantity > 0)
            ) && (
              <div className="bg-white shadow sm:rounded-lg p-4 pb-2 mb-8 mx-auto max-w-7xl">
                <p className="text-gray-500 font-xs text-center">
                  You can only add more pipelines after the free trial. If you&apos;d like more
                  please activate the trial!
                </p>
              </div>
            )}
        </>
      )}

      <div className="mx-auto max-w-7xl lg:p-6 bg-white shadow sm:rounded-lg">
        {/* Mobile view */}
        <div className="mx-auto max-w-md space-y-8 lg:hidden">
          {modifiedTiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
                'p-6'
              )}
            >
              <h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900">
                {tier.name}
              </h3>
              {/* NEVER REMOVE THIS COMMENT. THIS IS WHERE THE MAIN PRICING GOES */}
              <p className="text-xs font-normal leading-5 text-gray-500">{tier.description}</p>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-900 h-[50px]">
                <span
                  className={`${getTextSizeClass(
                    calculateTotalPrice(tier, billingCycle)
                  )} font-bold`}
                >
                  {currencySymbol}
                  {calculateTotalPrice(tier, billingCycle)}
                </span>
                <span className="text-sm font-semibold">{getBillingCycleText(billingCycle)}</span>
              </p>
              <button type="button" {...getButtonProps(tier)} />
              <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.id] ? (
                          <li key={feature.id} className="flex gap-x-3">
                            <CheckIcon
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-red-damask-600"
                            />
                            <span>
                              {feature.name}{' '}
                              {typeof feature.tiers[tier.id] === 'string' ? (
                                <span className="text-sm leading-6 text-gray-500">
                                  ({feature.tiers[tier.id]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* Desktop view */}
        <div className="isolate hidden lg:block">
          <div className="relative -mx-8">
            {modifiedTiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  style={{
                    marginLeft: `${
                      (modifiedTiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                    }%`,
                  }}
                  aria-hidden="true"
                  className="flex w-1/4 px-4"
                >
                  <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                {modifiedTiers.map((tier) => (
                  <col key={tier.id} className="w-1/4" />
                ))}
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {modifiedTiers.map((tier) => (
                    <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                      <div className="text-sm font-semibold leading-7 text-gray-900">
                        {tier.name}
                      </div>
                      <div className="text-xs font-normal leading-5 text-gray-500">
                        {tier.description}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {modifiedTiers.map((tier) => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      {renderPriceSection(tier)}
                      <button {...getButtonProps(tier)} />
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? 'pt-8' : 'pt-16',
                          'pb-4 text-sm font-semibold leading-6 text-gray-900'
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.id}>
                        <th
                          scope="row"
                          className="py-4 text-sm font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {modifiedTiers.map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.id] === 'string' ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {formatFeatureValue(tier, feature)}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.id] === true ? (
                                  <CheckIcon
                                    aria-hidden="true"
                                    className="mx-auto h-5 w-5 text-red-damask-600"
                                  />
                                ) : (
                                  <MinusIcon
                                    aria-hidden="true"
                                    className="mx-auto h-5 w-5 text-gray-400"
                                  />
                                )}
                                <span className="sr-only">
                                  {feature.tiers[tier.id] === true ? 'Included' : 'Not included'} in{' '}
                                  {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
