import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const CommonActionSingle = ({ title, buttonName, href, description, className }) => {
  return (
    <div className={`bg-white shadow sm:rounded-lg ${className || ''}`}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          {React.isValidElement(description) ? description : <p>{description}</p>}
        </div>
        <div className="mt-5">
          <Link
            to={href}
            className="inline-flex items-center rounded-md bg-deep-sea-green-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-deep-sea-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-deep-sea-green-500"
          >
            {buttonName}
          </Link>
        </div>
      </div>
    </div>
  );
};

CommonActionSingle.propTypes = {
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
};

// Add in prop validation

export default CommonActionSingle;
