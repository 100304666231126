// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IY2ZaDyK_pYOIIy3PyQ7{background-color:#006f71;padding:2px 4px;color:#fff;border-radius:2px;border:1px solid #00b6b2}.IY2ZaDyK_pYOIIy3PyQ7 span{color:#fff}.fyFilQd3loP3qYY_kAwf{background-color:#006f71;padding:2px 0px}.av8pJubUhV7krnlb_kck{background-color:#006f71;padding:2px 0px}.A1HYHKqpdATKOeGkWTkD{background-color:red;padding:2px 0px}.cm-editor{font-size:16px}`, "",{"version":3,"sources":["webpack://./frontend/js/components/SQLEditor/SQLEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CAEF,2BACE,UAAA,CAGF,sBACE,wBAAA,CACA,eAAA,CAGF,sBACE,wBAAA,CACA,eAAA,CAGF,sBACE,oBAAA,CACA,eAAA,CAIA,WACE,cAAA","sourcesContent":[".highlighted-word-columns {\n  background-color: #006f71;\n  padding: 2px 4px;\n  color: #ffffff;\n  border-radius: 2px;\n  border: 1px solid #00b6b2;\n}\n.highlighted-word-columns span {\n  color: #fff;\n}\n\n.highlighted-word-in-braces {\n  background-color: #006f71;\n  padding: 2px 0px;\n}\n\n.highlighted-space {\n  background-color: #006f71;\n  padding: 2px 0px;\n}\n\n.error-location {\n  background-color: #ff0000;\n  padding: 2px 0px;\n}\n\n\n  :global(.cm-editor) {\n    font-size: 16px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted-word-columns": `IY2ZaDyK_pYOIIy3PyQ7`,
	"highlighted-word-in-braces": `fyFilQd3loP3qYY_kAwf`,
	"highlighted-space": `av8pJubUhV7krnlb_kck`,
	"error-location": `A1HYHKqpdATKOeGkWTkD`
};
export default ___CSS_LOADER_EXPORT___;
