import { useMemo } from 'react';

import {
  useGetAccountsQuery,
  useGetPipelineRunsQuery,
  useGetPipelinesQuery,
} from 'services/rtkApi';

/**
 * Hook to fetch and process data for the admin dashboard
 */
export const useAdminDashboardData = () => {
  // Get yesterday's date range
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(23, 59, 59, 999);

  // Fetch all required data
  const { data: accounts, isLoading: isAccountsLoading } = useGetAccountsQuery({
    no_pagination: true,
  });
  const { data: pipelines, isLoading: isPipelinesLoading } = useGetPipelinesQuery({
    no_pagination: true,
  });
  const { data: pipelineRuns, isLoading: isRunsLoading } = useGetPipelineRunsQuery({
    no_pagination: true,
    created_at__gte: yesterday.toISOString(),
    created_at__lte: today.toISOString(),
  });

  // Process accounts data
  const accountsWithPipelines = useMemo(() => {
    if (!accounts || !pipelines) return [];
    return accounts.map((account) => ({
      ...account,
      pipelineCount: pipelines.filter((p) => p.account === account.id).length,
      subscription_status: account.subscription_status || null,
    }));
  }, [accounts, pipelines]);

  // Process pipeline statistics
  const pipelineStats = useMemo(() => {
    if (!pipelineRuns || !pipelines) return null;
    return {
      totalPipelines: pipelines.length,
      totalRuns: pipelineRuns.length,
      successfulRuns: pipelineRuns.filter((run) => run.did_run_succeed).length,
      failedRuns: pipelineRuns.filter((run) => run.did_run_succeed === false).length,
    };
  }, [pipelines, pipelineRuns]);

  return {
    accountsWithPipelines,
    pipelineStats,
    pipelineRuns: pipelineRuns || [],
    isLoading: isAccountsLoading || isPipelinesLoading || isRunsLoading,
  };
};
