import { BookOpenIcon, ClipboardDocumentListIcon, PlusIcon } from '@heroicons/react/24/outline';

import CommonActions from 'components/CommonActions/CommonActions';
import ListStacked from 'components/ListStacked/ListStacked';
import OnboardingChecklist from 'components/OnboardingChecklist/OnboardingChecklist';
import { useUserPermissions } from 'hooks/useUserPermissions';
import { ADMIN_DASHBOARD, PIPELINE_CREATE_FORM, PIPELINE_LIST } from 'routes/constants';
import { useGetCurrentAccountQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';

const actions = [
  {
    title: 'Add a new pipeline',
    href: PIPELINE_CREATE_FORM,
    icon: PlusIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    description: "You'll want one pipeline for each set of GA4 properties you want to record",
  },
  {
    title: 'Check your existing pipelines',
    href: PIPELINE_LIST,
    icon: ClipboardDocumentListIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    description: 'Look at the status of your existing pipelines and GA4 loads',
  },
  {
    title: 'Documentation',
    href: '#',
    icon: BookOpenIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-teal-50',
    description: "We've got a lot of documentation to help you get started with your GA4 data.",
  },
];

const adminItems = [
  {
    id: 'admin-dashboard',
    name: 'Admin Dashboard',
    description: 'View system-wide statistics and monitor pipeline runs',
  },
];

const Home = () => {
  const { data: currentAccount } = useGetCurrentAccountQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { isAdmin } = useUserPermissions();

  const isOnboardingComplete = () => {
    if (!currentAccount?.onboarding_status_dict) return false;
    return Object.values(currentAccount.onboarding_status_dict).every((status) => status === true);
  };

  const handleAdminItemClick = (item) => {
    if (item.id === 'admin-dashboard') {
      window.location.href = ADMIN_DASHBOARD;
    }
  };

  return (
    <PageLayout>
      <div className="px-4 sm:px-6 lg:px-8 py-10 mx-20">
        {isOnboardingComplete() ? (
          <>
            <CommonActions actions={actions} />
            {isAdmin && (
              <div className="mt-8">
                <ListStacked
                  items={adminItems}
                  onItemClick={handleAdminItemClick}
                  header={{
                    title: 'Admin Actions',
                    description: 'Quick access to administrative tools and dashboards',
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <OnboardingChecklist onboardingStatus={currentAccount?.onboarding_status_dict || {}} />
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Home;
