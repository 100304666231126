import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'components/Button/Button';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import FormInput from 'components/Forms/FormInput/FormInput';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { showErrorToast, showSuccessToast } from 'components/Toaster/ToastUtils';
import {
  useCancelInvitationMutation,
  useGetInvitationsQuery,
  useInviteUserMutation,
} from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * Page for managing user invitations to the current account
 */
const UserInvitationsPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // API hooks
  const { data: invitations, isLoading, refetch } = useGetInvitationsQuery();
  const [inviteUser] = useInviteUserMutation();
  const [cancelInvitation] = useCancelInvitationMutation();

  /**
   * Handle form submission to invite a user
   */
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await inviteUser(data).unwrap();
      showSuccessToast(`Invitation sent to ${data.email}`);
      reset();
      refetch();
    } catch (err) {
      showErrorToast(err.data?.detail || 'Failed to send invitation');
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Handle cancellation of a pending invitation
   */
  const handleCancelInvitation = async (invitationId) => {
    try {
      await cancelInvitation(invitationId).unwrap();
      showSuccessToast('Invitation cancelled');
      refetch();
    } catch (err) {
      showErrorToast(err.data?.detail || 'Failed to cancel invitation');
    }
  };

  /**
   * Table columns configuration for the invitations table
   */
  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        filter: { enabled: true },
      },
      {
        Header: 'Invited On',
        accessor: 'invited_at',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: 'Days Until Expiry',
        accessor: 'days_until_expiry',
        Cell: ({ value }) => (value ? `${value} days` : 'Expired'),
      },
    ],
    []
  );

  /**
   * Button configuration for the invitations table
   */
  const linkButtons = useMemo(
    () => [
      {
        label: 'Cancel',
        onClick: (invitation) => handleCancelInvitation(invitation.id),
      },
    ],
    [handleCancelInvitation]
  );

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Invite Users</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="space-y-6 mt-6">
            {/* Invitation Form */}
            <div className="bg-white shadow sm:rounded-lg p-6">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Invite a New User</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Send an invitation to a team member to join your account.
              </p>

              <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <FormInput
                      fieldKey="email"
                      label="Email Address"
                      register={register}
                      errors={errors.email}
                      placeholder="Enter email address"
                      type="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                    />
                  </div>
                  <div className="sm:col-span-4">
                    <Button
                      type="submit"
                      className="mt-3"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Send Invitation
                    </Button>
                  </div>
                </div>
              </form>
            </div>

            {/* Pending Invitations */}
            <div className="bg-white shadow sm:rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:px-6">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Pending Invitations
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Manage invitations that have been sent but not yet accepted.
                </p>
              </div>

              <div className="p-4">
                {isLoading ? (
                  <div className="text-center py-4">Loading...</div>
                ) : (
                  <FlexListTable
                    columns={columns}
                    data={invitations || []}
                    linkButtons={linkButtons}
                    hiddenColumnIds={['id']}
                  />
                )}
              </div>
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default UserInvitationsPage;
