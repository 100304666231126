/**
 * Hook for previewing Paddle price changes, particularly useful for addon modifications
 * Returns formatted and raw price previews for subscription changes
 */
import { useCallback, useState } from 'react';

import { formatPreviewItems, getCurrentItems } from './paddleUtils';
import { usePaddleInstance } from './usePaddleInstance';

export function usePaddlePricePreview(onEventCallback) {
  const { paddle, error: paddleError } = usePaddleInstance(onEventCallback);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const previewPrice = useCallback(
    async ({ subscription, newItems }) => {
      if (!paddle) return null;
      if (!newItems?.length) return null;

      setIsLoading(true);
      setError(null);

      try {
        const currentItems = getCurrentItems(subscription);
        const formattedItems = formatPreviewItems(newItems);

        const request = {
          items: formattedItems,
        };

        const result = await paddle.PricePreview(request);

        // Calculate totals from line items (converting from pence to pounds)
        const newTotal =
          result.data.details.lineItems.reduce(
            (sum, item) => sum + parseInt(item.totals.subtotal),
            0
          ) / 100;

        const currentTotal = currentItems.reduce((sum, item) => sum + item.total / 100, 0);

        // Get currency symbol from first line item
        const currencySymbol = result.data.details.lineItems[0].formattedTotals.total[0];

        return {
          currencyCode: result.data.currencyCode,
          formattedTotal: `${currencySymbol}${newTotal.toFixed(2)}`,
          rawTotal: newTotal,
          difference: {
            raw: newTotal - currentTotal,
            formatted: `${currencySymbol}${Math.abs(newTotal - currentTotal).toFixed(2)}`,
          },
          lineItems: result.data.details.lineItems,
        };
      } catch (err) {
        setError(`Failed to preview prices: ${err.message}`);
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [paddle]
  );

  return {
    previewPrice,
    isLoading,
    error: error || paddleError,
  };
}
