import { useDispatch, useSelector } from 'react-redux';

import { useUserPermissions } from 'hooks/useUserPermissions';
import { setBypassTenant } from 'services/auth/authSlice';

/**
 * Hook for managing tenant bypass functionality
 *
 * This hook provides methods to enable/disable tenant bypassing
 * and access the current bypass state. It only works for staff/superusers.
 * Toggling the bypass state will refresh the page to ensure all data is reloaded.
 *
 * @returns {Object} Object with bypass state and control methods
 */
const useBypassTenant = () => {
  const dispatch = useDispatch();
  const bypassTenant = useSelector((state) => state.auth.bypassTenant);
  const { isAdmin } = useUserPermissions();

  const refreshPage = () => {
    window.location.reload();
  };

  const enableBypass = () => {
    if (isAdmin) {
      dispatch(setBypassTenant(true));
      refreshPage();
    }
  };

  const disableBypass = () => {
    dispatch(setBypassTenant(false));
    refreshPage();
  };

  const toggleBypass = () => {
    if (isAdmin) {
      dispatch(setBypassTenant(!bypassTenant));
      refreshPage();
    } else {
      dispatch(setBypassTenant(false));
    }
  };

  return {
    isEnabled: bypassTenant,
    enableBypass,
    disableBypass,
    toggleBypass,
    canBypass: isAdmin,
  };
};

export default useBypassTenant;
