import PropTypes from 'prop-types';
import React from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable';
import TextBubble from 'components/TextBubble/TextBubble';

/**
 * Table displaying pipeline runs
 */
const PipelineRunsTable = ({ runs, onRunClick }) => {
  // Transform the data to add a computed status field
  const transformedData = React.useMemo(
    () =>
      runs?.map((run) => ({
        ...run,
        status: run.is_run_in_progress ? 'IN_PROGRESS' : run.did_run_succeed ? 'SUCCESS' : 'FAILED',
      })) || [],
    [runs]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Pipeline',
        accessor: 'pipeline',
      },
      {
        Header: 'Status',
        accessor: 'status',
        filter: {
          enabled: true,
          enum: true,
        },
        Cell: ({ value }) => {
          const colorMap = {
            IN_PROGRESS: 'blue',
            SUCCESS: 'green',
            FAILED: 'red',
          };
          return <TextBubble color={colorMap[value]} text={value} />;
        },
      },
      {
        Header: 'Started At',
        accessor: 'created_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: 'Completed At',
        accessor: 'completed_at',
        Cell: ({ value }) => (value ? new Date(value).toLocaleString() : '-'),
      },
    ],
    []
  );

  const linkButtons = [
    {
      label: 'View Details',
      onClick: (row) => onRunClick(row.id),
    },
  ];

  if (!runs || runs.length === 0) {
    return (
      <div className="text-center text-gray-500 py-4">No pipeline runs found for yesterday</div>
    );
  }

  return (
    <FlexListTable
      columns={columns}
      data={transformedData}
      linkButtons={linkButtons}
      initialSort={[{ id: 'created_at', desc: true }]}
    />
  );
};

PipelineRunsTable.propTypes = {
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      pipeline: PropTypes.number.isRequired,
      did_run_succeed: PropTypes.bool,
      is_run_in_progress: PropTypes.bool.isRequired,
      created_at: PropTypes.string.isRequired,
      completed_at: PropTypes.string,
    })
  ),
  onRunClick: PropTypes.func.isRequired,
};

export default PipelineRunsTable;
