import TenantBypassIndicator from 'components/TenantBypassIndicator';
import TenantBypassToggle from 'components/TenantBypassToggle';
import { useUserPermissions } from 'hooks/useUserPermissions';

/**
 * Admin toolbar component for displaying admin-only tools in the navbar
 * Only visible to staff/superusers
 *
 * @param {Object} props - Component props
 * @param {string} props.className - Additional CSS classes
 */
const AdminToolbar = ({ className = '' }) => {
  const { isAdmin, isLoading } = useUserPermissions();

  // Don't render anything if loading or user is not an admin
  if (isLoading || !isAdmin) return null;

  return (
    <div className={`border-l border-gray-200 pl-4 flex items-center space-x-4 ${className}`}>
      <TenantBypassToggle />
      <TenantBypassIndicator />
    </div>
  );
};

export default AdminToolbar;
