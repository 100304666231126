import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import { useUserPermissions } from 'hooks/useUserPermissions';
import {
  fieldInfoUpgradeSourceGA4BigQuery,
  upgradeSourceGA4BigQuerySchemaForm,
} from 'pages/models/source_ga4_bigquery/UpgradeSource/schema/upgradeSourceGA4BigQuerySchema';
import {
  useGetDbtPackageQuery,
  useGetFilteredRepoVersionsQuery,
  useUpdateDbtPackageMutation,
} from 'services/rtkApi';

const UseCustomGetDbtPackageQuery = (pipelineId, packageName) => {
  // Use the existing RTK API hook with both pipelineId and packageName
  return useGetDbtPackageQuery({ pipelineId, packageName });
};

/**
 * Helper function to compare versions
 */
const isVersionDowngrade = (currentVersion, newVersion) => {
  try {
    // Simple version comparison for semver-like versions
    const stripV = (v) => v.replace(/^v/i, '');
    const current = stripV(currentVersion).split('.').map(Number);
    const newer = stripV(newVersion).split('.').map(Number);

    // Compare major, minor, patch
    for (let i = 0; i < Math.max(current.length, newer.length); i += 1) {
      const c = current[i] || 0;
      const n = newer[i] || 0;
      if (n < c) return true;
      if (n > c) return false;
    }

    return false; // Same version
  } catch (err) {
    console.error('Error comparing versions:', err);
    return false; // On error, assume it's not a downgrade
  }
};

const UpgradeSourceGA4BigQueryForm = ({ onSuccess }) => {
  const { pipelineId } = useParams();
  const packageName = 'po-package-ga4'; // The GA4 dbt package name

  // Use the proper permissions hook
  const { isAdmin } = useUserPermissions();

  // Get current package data
  const { data: packageData } = UseCustomGetDbtPackageQuery(pipelineId, packageName);

  // Extract correct field names
  const repoUrl = packageData?.git || '';
  const currentVersion = packageData?.revision || '';

  // Get available versions from the API
  const {
    data: filteredVersionsData,
    isLoading: versionsLoading,
    error: versionsError,
  } = useGetFilteredRepoVersionsQuery(
    {
      repoUrl,
      currentVersion,
      packageName,
    },
    { skip: !repoUrl } // Skip if no git URL is available
  );

  // Process versions with client-side filtering
  const processedVersions = useMemo(() => {
    if (!filteredVersionsData?.versions) {
      return [];
    }

    // Sort versions to identify the latest one
    const sortedVersions = [...filteredVersionsData.versions].sort((a, b) => {
      try {
        const stripV = (v) => v.replace(/^v/i, '');
        const verA = stripV(a).split('.').map(Number);
        const verB = stripV(b).split('.').map(Number);

        for (let i = 0; i < Math.max(verA.length, verB.length); i += 1) {
          const numA = verA[i] || 0;
          const numB = verB[i] || 0;
          if (numA !== numB) return numB - numA; // Descending order
        }
        return 0;
      } catch (err) {
        console.error('Error sorting versions:', err);
        return 0;
      }
    });

    const latestVersion = sortedVersions.length > 0 ? sortedVersions[0] : null;

    const options = filteredVersionsData.versions
      // Apply downgrade protection for non-staff
      .filter((version) => {
        if (!currentVersion) return true;
        const isDowngrade = isVersionDowngrade(currentVersion, version);
        // Always include the latest version, otherwise filter by staff permissions
        return isAdmin || !isDowngrade || (latestVersion && version === latestVersion);
      })
      // Map to dropdown format
      .map((version) => {
        const isDowngrade = currentVersion ? isVersionDowngrade(currentVersion, version) : false;

        // Mark the latest version in the label
        const isLatest = latestVersion && version === latestVersion;
        let label = version;

        if (isDowngrade) {
          label = `${version} (Downgrade)`;
        }
        if (isLatest) {
          label = `${label} (Latest)`;
        }

        return {
          value: version,
          label,
        };
      });

    // Add po-pipeline option for staff users
    if (isAdmin) {
      options.unshift({
        value: 'po-pipeline',
        label: 'po-pipeline (Staff Only)',
        description: 'Special development branch for staff only.',
      });
    }

    return options;
  }, [filteredVersionsData, currentVersion, isAdmin]);

  // Prepare foreign key options for the version dropdown
  const foreignKeyOptions = [
    {
      key: ['revision'], // Match the field name in the schema
      options: processedVersions,
    },
  ];

  const queryHook = () => UseCustomGetDbtPackageQuery(pipelineId, packageName);
  const mutationHook = useUpdateDbtPackageMutation;

  if (versionsError) {
    return (
      <div className="bg-red-50 text-red-500 p-4 rounded-md mt-4">
        Error loading available versions: {versionsError.toString()}
      </div>
    );
  }

  return (
    <div className="mt-4">
      <CrudForm
        entityId={pipelineId}
        key={`upgrade-source-ga4-bigquery`}
        formType="update"
        mutationHook={mutationHook}
        queryHook={queryHook}
        schema={upgradeSourceGA4BigQuerySchemaForm}
        fieldInfo={fieldInfoUpgradeSourceGA4BigQuery}
        foreignKeyOptions={foreignKeyOptions}
        loadingForeignKeys={versionsLoading ? ['revision'] : []}
        initialValues={{
          package_name: packageName,
        }}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export default UpgradeSourceGA4BigQueryForm;
