import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setSubscriptionComplete } from 'services/pricing/pricingSlice';
import { useGetCurrentUserQuery } from 'services/rtkApi';
import { useBasePricingTable } from './usePricingTableBase';

/**
 * Custom hook for managing new subscription pricing table logic
 * @param {Object} props
 * @param {Array} props.initialTiers - Initial pricing tiers
 */
export const usePricingTable = ({ initialTiers }) => {
  const dispatch = useDispatch();

  // Get current user data
  const { data: currentUser, error: userError, isLoading: userLoading } = useGetCurrentUserQuery();

  // Set the logic for handling paddle events
  const handlePaddleEvent = (data) => {
    if (data.name === 'checkout.closed' && data.data?.status === 'completed') {
      dispatch(setSubscriptionComplete());
    }
  };

  // Use base pricing table hook
  const {
    localTiers,
    billingCycle,
    setBillingCycle,
    error,
    isLoading,
    paddle,
    currency,
    currencySymbol,
    utils,
    addonQuantities,
    handleAddonQuantityChange,
    calculateTotalPrice,
  } = useBasePricingTable({ initialTiers, onPaddleEvent: handlePaddleEvent });

  // Modified checkout handler
  const handleCheckout = useCallback(
    (tier, billingCycle) => {
      if (!paddle || !currentUser?.account) {
        console.error('Paddle not initialized or account missing');
        return;
      }

      const items = [
        {
          priceId: tier.priceId[billingCycle],
          quantity: 1,
        },
      ];

      paddle.Checkout.open({
        items,
        customer: {
          email: currentUser?.email,
        },
        customData: {
          account_id: currentUser.account,
        },
      });
    },
    [paddle, currentUser]
  );

  return {
    // State from base hook
    localTiers,
    billingCycle,
    currentUser,

    // Loading/Error states
    isLoading,
    userLoading,
    error,
    userError,

    // Actions
    setBillingCycle,
    handleCheckout,

    // Addon functionality
    addonQuantities,
    handleAddonQuantityChange,
    calculateTotalPrice,

    // Utility functions and currency info
    utils,
    currency,
    currencySymbol,
  };
};
