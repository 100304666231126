import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import FormInput from 'components/Forms/FormInput/FormInput';
import LoggedOutLegalLinks from 'components/LegalLinks/LoggedOutLegalLinks';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SignedOutContainer from 'components/SignedOutContainer/SignedOutContainer';
import { resetPasswordConfirm } from 'services/auth/auth';
import LoggedOutLayout from 'templates/LoggedOutLayout';

const PasswordReset = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await resetPasswordConfirm(uid, token, data.new_password);
      toast.success('Password reset successful. You can now login with your new password.');
      navigate('/login');
    } catch (error) {
      toast.error(error.response?.data?.detail || 'Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Redirect if no uid or token
  useEffect(() => {
    if (!uid || !token) {
      navigate('/login');
    }
  }, [uid, token, navigate]);

  return (
    <LoggedOutLayout>
      <SignedOutContainer>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6"
        >
          <FormInput
            fieldKey="new_password"
            fieldName="new_password"
            type="password"
            register={register}
            errors={errors}
            placeholder="Enter your new password"
            validation={{
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            }}
            unformattedToTitleCase={(str) => str.charAt(0).toUpperCase() + str.slice(1)}
          />
          <FormInput
            fieldKey="confirm_password"
            fieldName="confirm_password"
            type="password"
            register={register}
            errors={errors}
            placeholder="Confirm your new password"
            validation={{
              required: 'Please confirm your password',
              validate: (value) => value === watch('new_password') || 'The passwords do not match',
            }}
            unformattedToTitleCase={(str) => str.charAt(0).toUpperCase() + str.slice(1)}
          />

          {isLoading ? (
            <LoadingSpinner text="Resetting password..." size="8" />
          ) : (
            <Button type="submit" variant="primary" className="w-full">
              Reset Password
            </Button>
          )}

          <LoggedOutLegalLinks />
        </form>
      </SignedOutContainer>
    </LoggedOutLayout>
  );
};

export default PasswordReset;
