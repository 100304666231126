import { z } from 'zod';

import { propertyConditionOnlyConditionsSchema } from '../../_schema/sourceGA4PropertiesFormBase';

/**
 * DEFINTIION: Advanced Configuration
 */
export const sourceGA4BigQueryAdvancedSchemaForm = z.object({
  // How many days lookback is our attribution window?
  lndc_attribution_window_days: z.number().optional().default(90),

  // Do we want to use user ID?
  user_id_enabled: z.boolean().optional(),

  // Do we want to force medium UTM to be lowercase?
  force_lower_medium_event_param: z.boolean().optional(),

  // Do we want to split sessions on new source?
  split_session_on_new_source: z.enum(['dont_split', 'default_tables', 'new_tables']).optional(),

  // Filter events from source splitting
  filter_events_from_source_splitting: propertyConditionOnlyConditionsSchema,
});

export const fieldInfoSourceGA4DBTAdvancedSchema = {
  lndc_attribution_window_days: {
    name: 'Attribution Window',
    description:
      'How many days lookback do you want to use for the default GA4 attribution window? (GA4 uses 90 by default).',
  },
  force_lower_medium_event_param: {
    name: 'Force medium UTM to be lowercase',
    description:
      'Do you want to force any manually set medium UTMs to be lowercase? Typically you do as GA4 only uses lowercase values by default.',
  },
  user_id_enabled: { name: 'User ID Enabled', description: 'Are you using user ID?' },
  split_session_on_new_source: {
    name: 'Split Session on New Source',
    description: 'Do you want to split sessions on new source? (This is how UA used to do it.).',
  },
  filter_events_from_source_splitting: {
    name: 'Filter Events from Source Splitting',
    description:
      "If we are splitting sessions on new source, you should filter out any events we don't want to split source on, e.g. payment providers.",
    showLabel: false,
    showDescription: false,
    showArrayLabel: false,
    showArrayDescription: false,
    conditionBlock: {
      name: 'When these conditions are true.',
      showLabel: false,
      showArrayLabel: false,
      fields: {
        andOr: {
          name: 'And/Or',
          description:
            'Choose whether all conditions should match (AND) or any condition should match (OR)',
        },
        conditions: {
          name: 'Add conditions for event filtering.',
          showLabel: false,
          fields: {
            col: {
              name: 'Column',
            },
            operator: {
              name: 'Operator',
            },
            value: {
              name: 'Value',
            },
          },
        },
      },
    },
  },
};
