import useBypassTenant from 'hooks/tenant/useBypassTenant';

/**
 * Visual indicator that tenant restrictions are bypassed
 *
 * @param {Object} props - Component props
 * @param {string} props.className - Additional CSS classes
 */
const TenantBypassIndicator = ({ className = '' }) => {
  const { isEnabled } = useBypassTenant();

  if (!isEnabled) return null;

  return (
    <div
      className={`bg-amber-100 text-amber-800 px-3 py-1 rounded-full text-sm font-medium ${className}`}
    >
      Tenant Restrictions Bypassed
    </div>
  );
};

export default TenantBypassIndicator;
