import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from 'components/Button/Button';
import { convertDatesInInitialData, getSchemaFromZod } from 'components/Forms/formUtils';
import renderSchema from 'components/Forms/renderSchema';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Tabs from 'components/Tabs/Tabs';
import { getChangedKeyPaths, getChangedValues } from 'utils/index';

import styles from './CrudForm.module.scss';
import useTabHandling from './useTabHandling';

/**
 * A flexible form component that handles CRUD operations with advanced features.
 *
 * Key Features:
 * - Zod schema validation
 * - Foreign key relationships
 * - Tabbed interfaces
 * - Field change tracking
 * - Automatic field population
 * - Custom validation
 *
 * @param {Object} props
 * @param {Object} props.schema - Zod schema defining form structure and validation
 * @param {Function} props.mutationHook - RTK Query mutation hook for create/update
 * @param {Function} props.queryHook - RTK Query hook for fetching entity data (update mode)
 * @param {string|number} props.entityId - ID of entity being edited (update mode)
 * @param {Function} props.onSuccess - Callback after successful submission
 * @param {'create'|'update'} props.formType - Form mode
 * @param {Array} props.foreignKeyOptions - Options for foreign key fields [{key: [], options: []}]
 * @param {Array} props.loadingForeignKeys - Fields currently loading foreign key data
 * @param {Array} props.tabs - Form tab configuration
 * @param {Function} props.onFieldChange - Callback when any field changes
 * @param {Object} props.fieldInfo - Additional field metadata (labels, descriptions)
 * @param {Function} props.preSubmit - Transform data before submission
 * @param {Function} props.postOnChange - Handle side effects after field changes
 * @param {Function} props.postOnChangeOptions - Update foreign key options after field changes
 * @param {Object} props.autoSetFields - Fields that are automatically populated
 * @param {Object} props.initialValues - Initial form values
 * @param {boolean} props.showTitleAndDescriptionColumn - Show left column with descriptions
 * @param {Object} props.methods - React Hook Form methods (optional)
 * @param {Array} props.hiddenFields - Fields to hide from rendering
 * @param {boolean} props.partialFileUpdate - Allow partial updates
 * @param {string} props.formTitle - Title shown at top of form
 * @param {Object} props.submitButtonOverride - Custom submit button config
 *
 * @example
 * // Basic create form
 * <CrudForm
 *   schema={myZodSchema}
 *   formType="create"
 *   mutationHook={useCreateMutation}
 * />
 *
 * @example
 * // Advanced update form with tabs
 * <CrudForm
 *   schema={myZodSchema}
 *   formType="update"
 *   entityId={id}
 *   mutationHook={useUpdateMutation}
 *   queryHook={useGetEntityQuery}
 *   tabs={[
 *     {
 *       key: 'main',
 *       tabName: 'Main Details',
 *       fieldList: ['name', 'email']
 *     }
 *   ]}
 *   onSuccess={handleSuccess}
 * />
 */
const CrudForm = ({
  schema,
  mutationHook,
  queryHook,
  entityId,
  onSuccess,
  formType,
  foreignKeyOptions,
  loadingForeignKeys,
  tabs,
  onFieldChange,
  fieldInfo,
  preSubmit,
  postOnChange,
  postOnChangeOptions,
  autoSetFields,
  initialValues,
  showTitleAndDescriptionColumn = true,
  methods: propMethods,
  hiddenFields = [],
  partialFileUpdate = false,
  formTitle,
  submitButtonOverride,
}) => {
  const dispatch = useDispatch();
  const contextMethods = useFormContext();

  // Always call useForm, but use its result only if needed
  const localMethods = useForm({
    resolver: zodResolver(schema, {
      async: true,
      validateAllFieldCriteria: true,
    }),
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  // Determine which methods to use
  const methods = propMethods || contextMethods || localMethods;

  // If we see initial values then we want to reset the form.
  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0 && formType === 'create') {
      methods.reset(initialValues);
    }
  }, [initialValues, methods, formType]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    // watch is not used but kept for potential future use
    // eslint-disable-next-line no-unused-vars
    watch,
    setValue,
    getValues,
  } = methods;

  const formValues = useWatch({ control, defaultValue: {} });
  // Get schema
  const baseSchema = useMemo(() => getSchemaFromZod(schema), [schema]);

  // Add this useEffect to log errors whenever they change
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.log('Form errors:', errors);
    }
  }, [errors]);

  const prevFormValuesRef = useRef({});

  const [foreignKeyOptionsDynamic, setForeignKeyOptionsDynamic] = useState(foreignKeyOptions);

  /**
   * When we get new foreignKeyOptions passed in from outside (e.g. external API calls)
   * we set them here whilst not overwriting any changes we've made with dynamic dropdown options.
   */
  useEffect(() => {
    // Update existing options and add new ones
    const updatedOptions = foreignKeyOptionsDynamic.map((dynamicOption) => {
      // Find a matching option in the new foreignKeyOptions
      const matchingOption = foreignKeyOptions.find((option) =>
        isEqual(option.key, dynamicOption.key)
      );

      if (matchingOption) {
        // If a match is found, update the options while keeping other properties
        return { ...dynamicOption, options: matchingOption.options };
      }
      // If no match is found, keep the existing option as is
      return dynamicOption;
    });

    // Add any new options from foreignKeyOptions that don't exist in foreignKeyOptionsDynamic
    foreignKeyOptions.forEach((option) => {
      // Check if this option doesn't already exist in updatedOptions
      if (!updatedOptions.some((updatedOption) => isEqual(updatedOption.key, option.key))) {
        // If it doesn't exist, add it to updatedOptions
        updatedOptions.push(option);
      }
    });

    // Only update state if there's a difference to avoid unnecessary rerenders
    if (!isEqual(updatedOptions, foreignKeyOptionsDynamic)) {
      setForeignKeyOptionsDynamic(updatedOptions);
    }
  }, [foreignKeyOptions, foreignKeyOptionsDynamic]);

  /**
   * Handle post on change
   */
  const handlePostOnChange = (changedFields, changedPaths) => {
    if (postOnChange) {
      // This needs to return an array of objects with path and value
      // the path can be JSON path

      // That path should be:
      // "field1.3.field2"
      // For a nested path.
      const updates = postOnChange(getValues(), changedFields, changedPaths);
      updates?.forEach(({ path, value }) => {
        if (value !== undefined && value !== null) {
          setValue(path, value);
        }
      });
    }
  };

  /**
   * Handle post on change options
   */
  const handlePostOnChangeOptions = useCallback(
    (formValues, changedFields, changedPaths) => {
      if (postOnChangeOptions) {
        // This needs to return an array of objects with path and options
        // The path can be a JSON path this time an array ["field1",3, "field2"]
        // The options are the options to be used for the foreign key
        const newOptionsArray = postOnChangeOptions(
          formValues,
          changedFields,
          changedPaths,
          foreignKeyOptionsDynamic
        );

        if (newOptionsArray && Array.isArray(newOptionsArray)) {
          const formattedOptions = newOptionsArray.map(({ key, options }) => ({
            key,
            options,
          }));

          setForeignKeyOptionsDynamic(formattedOptions);
        }
      }
    },
    [postOnChangeOptions, foreignKeyOptionsDynamic]
  );

  /**
   * Handle all of the post on change and on option change values.
   */
  useEffect(() => {
    const shouldProcessChanges = onFieldChange || postOnChange || postOnChangeOptions;

    if (shouldProcessChanges && !isEqual(prevFormValuesRef.current, formValues)) {
      const changedPaths = getChangedKeyPaths(prevFormValuesRef.current, formValues);
      const changedFields = getChangedValues(prevFormValuesRef.current, formValues);

      if (Object.keys(changedFields).length > 0) {
        onFieldChange?.(formValues, changedFields, changedPaths);
        handlePostOnChange(changedFields, changedPaths);
        handlePostOnChangeOptions(formValues, changedFields, changedPaths);
      }

      prevFormValuesRef.current = formValues;
    }
  }, [formValues, onFieldChange, handlePostOnChange, handlePostOnChangeOptions]);

  // Handle form updates with data from API.
  const [triggerMutation, { isLoading: isMutating }] = mutationHook();

  const {
    data: entityData,
    isSuccess,
    isLoading,
  } = entityId && queryHook ? queryHook(entityId) : { isLoading: false };

  const [buttonStatus, setButtonStatus] = useState(null);

  // Reset form values based on form type and entity data
  useEffect(() => {
    // Only run this effect when necessary

    if (formType === 'update' && isSuccess && entityData) {
      const convertedValues = convertDatesInInitialData(entityData, schema);
      // Set new form values
      reset(convertedValues);
    }
  }, [formType, isSuccess, entityData, initialValues, schema]);

  const {
    tabConfig,
    tabErrorCounts,
    currentTab,
    currentTabIndex,
    tabKeys,
    nextTab,
    previousTab,
    setCurrentTabByKey,
    hasTabs,
  } = useTabHandling(baseSchema, tabs, errors);

  /**
   * Handle the form submission
   */
  const onSubmit = async (data) => {
    // Create a new object to avoid mutating the original `data`
    let formattedData = { ...data };

    // Format date fields
    Object.keys(formattedData).forEach((key) => {
      const dateValue = formattedData[key];
      if (dateValue instanceof Date) {
        const [isoDate] = dateValue.toISOString().split('T');
        formattedData[key] = isoDate;
      }
    });

    // Apply preSubmit function if provided
    if (preSubmit) {
      // Create a copy of the data before the async operation to avoid race conditions
      const dataToProcess = { ...formattedData };
      formattedData = await preSubmit(dataToProcess);
    }

    try {
      let result;
      const mutationData =
        formType === 'update' && !partialFileUpdate
          ? { ...formattedData, id: formattedData.id || entityId }
          : formattedData;

      // Create the mutation promise without executing it
      const mutationPromise = triggerMutation(mutationData);

      // Check if the mutation has an unwrap method (RTK Query)
      if (typeof mutationPromise.unwrap === 'function') {
        result = await mutationPromise.unwrap();
      } else {
        // For custom mutation hooks
        result = await mutationPromise;
        if (result.error) throw result.error;
        result = result.data;
      }

      setButtonStatus(formType === 'create' ? 'created' : 'updated');

      if (onSuccess) {
        onSuccess(result.id);
      }
    } catch (error) {
      console.error('Form submission error:', error);
      console.error('Log form state errors', errors);
      setButtonStatus('failed');

      // If the error has a response property (typical for API errors), we can also dispatch it
      // This will be caught by the watchNonAuthApiErrors saga
      if (error.response) {
        dispatch({
          type: 'API_ERROR',
          payload: {
            status: error.response.status,
            data: error.response.data,
          },
        });
      }
    }

    setTimeout(() => setButtonStatus(null), 1000);
  };

  // Function to handle tabs and decide which fields to render
  const generateFieldsForCurrentTab = (currentTab) => {
    const fieldsForTab = hasTabs ? currentTab.fields : Object.keys(baseSchema.shape);

    return fieldsForTab
      .filter((key) => !hiddenFields.includes(key))
      .map((key) => {
        const fieldSchema = baseSchema.shape[key];
        // const currentFieldInfo = fieldInfo && fieldInfo[key] ? fieldInfo[key] : {};

        // console.log('schemaShape', baseSchema.shape);
        // console.log('fieldInfo', fieldInfo);
        // console.log('key', key);
        // console.log('currentFieldInfo', currentFieldInfo);
        return (
          <Fragment key={key}>
            {/* Update renderSchema to use baseSchema */}
            {renderSchema({
              schema: fieldSchema,
              name: key,
              control,
              register,
              errors,
              depth: 0,
              path: [key],
              fieldInfo,
              foreignKeyOptions: foreignKeyOptionsDynamic,
              loadingForeignKeys,
              setValue,
              autoSetFields,
            })}
          </Fragment>
        );
      });
  };

  // const renderCount = useRef(0);
  // const prevPropsRef = useRef({});

  /**
   * Additional debugging as this is a complex form.
   */
  // useEffect(() => {
  //   renderCount.current += 1;
  //   const changedProps = Object.keys(prevPropsRef.current).filter(
  //     (key) =>
  //       !isEqual(
  //         prevPropsRef.current[key],
  //         { foreignKeyOptions, postOnChangeOptions, formType, entityId }[key]
  //       )
  //   );

  //   prevPropsRef.current = { foreignKeyOptions, postOnChangeOptions, formType, entityId };
  // });

  // const prevForeignKeyOptionsRef = useRef(foreignKeyOptions);
  // const prevForeignKeyOptionsDynamicRef = useRef(foreignKeyOptionsDynamic);

  // useEffect(() => {
  //   if (!isEqual(prevForeignKeyOptionsRef.current, foreignKeyOptions)) {
  //     prevForeignKeyOptionsRef.current = foreignKeyOptions;
  //   }
  //   if (!isEqual(prevForeignKeyOptionsDynamicRef.current, foreignKeyOptionsDynamic)) {
  //     prevForeignKeyOptionsDynamicRef.current = foreignKeyOptionsDynamic;
  //   }
  // });

  // console.log('isLoading', isLoading);
  // console.log('formType', formType);

  return (
    <>
      {hasTabs && (
        <Tabs
          tabs={tabConfig}
          errorCounts={tabErrorCounts}
          currentTab={currentTab.key}
          onTabClick={setCurrentTabByKey}
        />
      )}

      {formType === 'update' && isLoading && (
        <div className="flex justify-center my-8">
          <LoadingSpinner text="Loading form data..." size="10" />
        </div>
      )}

      {(!isLoading || formType !== 'update') && (
        <div className={`space-y-10 divide-y divide-gray-900/10 ${styles.crudForm}`}>
          <div
            className={`grid grid-cols-1 gap-x-8 gap-y-8 ${
              showTitleAndDescriptionColumn ? 'md:grid-cols-7' : ''
            }`}
          >
            {showTitleAndDescriptionColumn && hasTabs && (
              <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {currentTab.tabName}
                </h2>
                <div className="mt-1 text-sm leading-6 text-gray-600">
                  {currentTab.component ? (
                    <currentTab.component />
                  ) : (
                    currentTab.description || 'Fill in the fields below'
                  )}
                </div>
              </div>
            )}
            <form
              className={`bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl ${
                showTitleAndDescriptionColumn ? 'md:col-span-6' : ''
              }`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="px-4 py-6 sm:p-8">
                {formTitle && (
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">{formTitle}</h3>
                )}
                <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* Fields for the current tab. We only render current tab */}
                  {generateFieldsForCurrentTab(currentTab)}
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                {Object.keys(errors).length > 0 && (
                  <div className="bg-red-50 text-red-400 mr-10 p-3 rounded-md">
                    Form has errors. Please check tabs and fix can we, e.g., missing data.
                  </div>
                )}

                <Button
                  statusColour={
                    buttonStatus === 'created' || buttonStatus === 'updated'
                      ? 'success'
                      : buttonStatus === 'failed'
                      ? 'fail'
                      : ''
                  }
                  loading={isMutating}
                  type="submit"
                  variant="primary"
                  disabled={submitButtonOverride?.disabled}
                >
                  {buttonStatus === 'created' && <span>✓ Created</span>}
                  {buttonStatus === 'updated' && <span>✓ Updated</span>}
                  {buttonStatus === 'failed' && <span>✗ Failed</span>}
                  {!buttonStatus &&
                    (submitButtonOverride?.text || (formType === 'create' ? 'Create' : 'Save'))}
                </Button>
                {hasTabs && (
                  <>
                    <Button
                      disabled={currentTabIndex === 0}
                      onClick={previousTab}
                      variant="secondary"
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={currentTabIndex === tabKeys.length - 1}
                      onClick={nextTab}
                      variant="secondary"
                    >
                      Next
                    </Button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

CrudForm.defaultProps = {
  entityId: undefined,
  foreignKeyOptions: [],
  loadingForeignKeys: [],
  queryHook: undefined,
  onSuccess: undefined,
  tabs: undefined,
  preSubmit: undefined,
  postOnChange: undefined,
  postOnChangeOptions: undefined,
  autoSetFields: {},
  initialValues: {},
  showTitleAndDescriptionColumn: true,
  hiddenFields: [],
  submitButtonOverride: undefined,
  methods: undefined,
};

CrudForm.propTypes = {
  schema: PropTypes.object.isRequired,
  mutationHook: PropTypes.func.isRequired,
  queryHook: PropTypes.func,
  entityId: PropTypes.any,
  onSuccess: PropTypes.func,
  formType: PropTypes.oneOf(['create', 'update']).isRequired,
  foreignKeyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.array.isRequired,
      options: PropTypes.array.isRequired,
    })
  ),
  loadingForeignKeys: PropTypes.array,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      tabName: PropTypes.string.isRequired,
      fieldList: PropTypes.arrayOf(PropTypes.string).isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    })
  ),
  onFieldChange: PropTypes.func,
  fieldInfo: PropTypes.object,
  preSubmit: PropTypes.func,
  postOnChange: PropTypes.func,
  postOnChangeOptions: PropTypes.func,
  autoSetFields: PropTypes.object,
  initialValues: PropTypes.object,
  showTitleAndDescriptionColumn: PropTypes.bool,
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  formTitle: PropTypes.string,
  partialFileUpdate: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  customValidation: PropTypes.objectOf(PropTypes.func),
  submitButtonOverride: PropTypes.shape({
    text: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  methods: PropTypes.object,
};

export default CrudForm;
