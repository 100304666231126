import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Tooltip from 'components/Tooltip/Tooltip';
import { usePipelinePolling } from 'hooks/usePipelinePolling';
import {
  useGetLatestPipelineRunQuery,
  useGetSourceGA4BigQueryByIdQuery,
  useRunPipelineMutation,
} from 'services/rtkApi';

/**
 * Calculates the current progress of the GA4 setup pipeline
 * @param {Object} params - Parameters for progress calculation
 * @param {string} params.buildStatus - Current build status from backend
 * @param {boolean} params.isRunning - Whether pipeline is currently running
 * @returns {{
 *   steps: Array<{name: string, href: string, isRunning: boolean, isSuccess: boolean}>,
 *   currentStep: string
 * }}
 */
const calculateCurrentProgress = ({ buildStatus, isRunning }) => {
  const setupSteps = [
    { name: 'Raw Data Copied', href: '#' },
    { name: 'Base Table', href: '#' },
    { name: 'Intermediate Tables', href: '#' },
    { name: 'Output Tables', href: '#' },
    { name: 'Complete', href: '#' },
  ];

  const statusToStepName = {
    not_started: 'Not Started',
    raw_data_copied: 'Raw Data Copied',
    base_table_built: 'Base Table',
    intermediate_tables_built: 'Intermediate Tables',
    output_tables_built: 'Output Tables',
    complete: 'Complete',
  };

  const lastCompletedStep = statusToStepName[buildStatus] || 'Not Started';
  const lastCompletedIndex = setupSteps.findIndex((step) => step.name === lastCompletedStep);

  const currentStep =
    buildStatus === 'complete'
      ? 'Complete'
      : lastCompletedIndex < setupSteps.length - 1
      ? setupSteps[lastCompletedIndex + 1].name
      : lastCompletedStep;

  const stepsWithStatus = setupSteps.map((step, index) => ({
    ...step,
    isRunning: buildStatus !== 'complete' && step.name === currentStep && isRunning,
    isSuccess: index <= lastCompletedIndex || buildStatus === 'complete',
  }));

  return {
    steps: stepsWithStatus,
    currentStep,
  };
};

/**
 * Renders buttons for updating dbt project and running pipeline, along with pipeline status.
 * @param {Object} props - Component props
 * @param {number} props.pipelineId - ID of the pipeline
 * @param {number} props.sourceGA4BigQueryID - ID of the SourceGA4BigQuery
 * @param {string} props.className - Additional CSS classes for the container
 */
function SourceGA4BigQueryFormActionButtons({ pipelineId, sourceGA4BigQueryID, className }) {
  /**
   * Running the pipeline and showing the status.
   *
   * Pipeline runs aren't identical. Because we want to autocomplete data for people.
   * The first time you run, we build it up step by step.
   */
  // Run the pipeline
  const [runPipeline, { isLoading: isRunningPipeline }] = useRunPipelineMutation();

  // Get the latest pipeline run
  const {
    data: latestPipelineRun,
    isLoading: isLoadingLatestRun,
    refetch: refetchLatestPipelineRun,
  } = useGetLatestPipelineRunQuery(pipelineId);

  // Get the source GA4 BigQuery
  const { data: sourceGA4BigQuery, refetch: refetchSourceGA4BigQuery } =
    useGetSourceGA4BigQueryByIdQuery(sourceGA4BigQueryID);

  // Set the statuses.
  const [buildStatus, setBuildStatus] = useState('not_started');
  useEffect(() => {
    if (sourceGA4BigQuery) {
      setBuildStatus(sourceGA4BigQuery.current_pipeline_build_status || 'not_started');
    }
  }, [sourceGA4BigQuery]);

  const isRunInProgress = latestPipelineRun?.is_run_in_progress || false;

  // Add this effect to refetch when run completes§
  useEffect(() => {
    if (!isRunInProgress) {
      // Run just completed, refetch both pipeline and source data
      refetchSourceGA4BigQuery();
      refetchLatestPipelineRun();
    }
  }, [isRunInProgress, refetchSourceGA4BigQuery, refetchLatestPipelineRun]);

  /**
   * RUN PIPELINE
   */
  const handleRunPipeline = async () => {
    let runType = 'ga4'; // Default run type

    // Fire the correct run type based on the build status.
    if (buildStatus === 'not_started') {
      runType = 'ga4_base_only_copy';
    } else if (buildStatus === 'raw_data_copied') {
      runType = 'ga4_base_only_events';
    } else if (buildStatus === 'base_table_built') {
      runType = 'ga4_post_base';
    }

    try {
      await runPipeline({ id: pipelineId, run_type: runType, full_refresh: true }).unwrap();
      // Refetch both the pipeline run and source GA4 data
      await Promise.all([refetchLatestPipelineRun(), refetchSourceGA4BigQuery()]);
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Failed to run pipeline:', error);
    }
  };

  const getRunButtonText = () => {
    if (isRunInProgress) {
      return 'Run in Progress';
    }
    if (buildStatus === 'not_started') {
      return 'Start Setup Run';
    }
    if (buildStatus === 'complete') {
      return 'Setup Runs Complete';
    }
    if (buildStatus === 'output_tables_built') {
      return 'Run GA4';
    }
    return 'Continue Setup Run';
  };

  // Poll the pipeline run to update parts of its
  usePipelinePolling({
    refetchFn: refetchLatestPipelineRun,
    isRunInProgress,
  });

  return (
    <div className={`flex items-center justify-end space-x-4 ${className}`}>
      <ProgressBar
        pipelineId={pipelineId}
        sourceGA4BigQueryID={sourceGA4BigQueryID}
        isRunInProgress={isRunInProgress}
      />
      {buildStatus === 'not_started' ? (
        <Tooltip
          position="bottom"
          content="Why do we have setup runs? They let us provide important feedback to you as you set-up your data pipeline."
        >
          <Button
            onClick={handleRunPipeline}
            loading={isRunningPipeline}
            disabled={
              isRunningPipeline || !buildStatus || isRunInProgress || buildStatus === 'complete'
            }
            variant="secondary"
          >
            {getRunButtonText()}
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={handleRunPipeline}
          loading={isRunningPipeline}
          disabled={
            isRunningPipeline || !buildStatus || isRunInProgress || buildStatus === 'complete'
          }
          variant="secondary"
        >
          {getRunButtonText()}
        </Button>
      )}
    </div>
  );
}

SourceGA4BigQueryFormActionButtons.propTypes = {
  pipelineId: PropTypes.string.isRequired,
  sourceGA4BigQueryID: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SourceGA4BigQueryFormActionButtons.defaultProps = {
  className: '',
};

export default SourceGA4BigQueryFormActionButtons;

// ARCHIVE
//   /**
//    * PIPELINE STATUS
//    * Set the current status of the pipeline.
//    * Is it running? Has it finished?
//    */
//   const [pipelineStatus, setPipelineStatus] = useState({ text: 'Unknown', color: 'gray' });
//   useEffect(() => {
//     if (latestPipelineRun === null) {
//       setPipelineStatus({ text: 'No runs yet', color: 'gray' });
//     } else if (latestPipelineRun) {
//       if (latestPipelineRun.is_run_in_progress) {
//         setPipelineStatus({ text: 'Running', color: 'blue' });
//       } else {
//         setPipelineStatus({
//           text: latestPipelineRun.did_run_succeed ? 'Success' : 'Failed',
//           color: latestPipelineRun.did_run_succeed ? 'green' : 'red',
//         });
//       }
//     }
//   }, [latestPipelineRun]);
