import { put, takeEvery } from 'redux-saga/effects';

import { addGlobalError } from './errorSlice';

// This saga only handles non-permission API errors
function* handleNonAuthApiError(action) {
  const { status, data } = action.payload || {};
  let message = data?.error || data?.detail || 'An error occurred during the request.';

  // Clean up DRF array-style error messages
  if (typeof message === 'string' && message.startsWith('[') && message.endsWith(']')) {
    try {
      const parsed = JSON.parse(message.replace(/'/g, '"'));
      message = Array.isArray(parsed) ? parsed[0] : message;
    } catch {
      // If parsing fails, keep original message
    }
  }

  // Check if the message contains 'client_custom_error_handle'
  if (!message.includes('client_custom_error_handle')) {
    if (status !== 401 && (status < 200 || status > 299)) {
      yield put(addGlobalError(message));
    } else if ((status === 401 || status === 403) && message.includes('Sorry')) {
      yield put(addGlobalError(message || 'You don&apos;t have permission to do that.'));
    }
  }
}

function* watchNonAuthApiErrors() {
  yield takeEvery(
    (action) => action.type.endsWith('rejected') && ![401].includes(action.payload?.status),
    handleNonAuthApiError
  );
}

export { watchNonAuthApiErrors };
