import PropTypes from 'prop-types';
import { z } from 'zod';

import FormCheckbox from './FormCheckbox/FormCheckbox';
import FormDatePicker from './FormDatePicker/FormDatePicker';
import FormHourSelector from './FormHourSelector/FormHourSelector';
import FormInput from './FormInput/FormInput';
import AndOrSelector from './FormLogicBuilder/CustomLayoutAndOrSelector';
import FormSelect from './FormSelect/FormSelect';
import FormTimezoneSelector from './FormTimezoneSelector/FormTimezoneSelector';
import {
  findForeignKeyOptions,
  getFieldInfo,
  getNestedError,
  getSchemaFromZod,
  getZodInnerType,
  isLoadingForeignKey,
} from './formUtils';

/**
 * Renders flat (non-object, non-array) fields based on their Zod schema type.
 * @param {Object} props - Rendering props
 * @param {z.ZodTypeAny} props.schema - Zod schema for the field
 * @param {string} props.name - Field name
 * @param {Object} props.control - React Hook Form control object
 * @param {Function} props.register - React Hook Form register function
 * @param {Object} props.errors - Form errors object
 * @param {Array} props.path - Current path in the form structure
 * @param {Object} props.fieldInfo - Additional field information
 * @param {Object} props.foreignKeyOptions - Foreign key options
 * @param {Object} props.loadingForeignKeys - Loading state for foreign keys
 * @param {Object} props.autoSetFields - Fields that are automatically set
 */
export const renderSchemaFlatField = ({
  schema,
  name,
  control,
  register,
  errors,
  path,
  fieldInfo,
  foreignKeyOptions,
  loadingForeignKeys,
  autoSetFields,
}) => {
  const currentFieldInfo = getFieldInfo(fieldInfo, path);
  const fieldName = name.split('.').pop();
  const label = currentFieldInfo.name || fieldName;
  const description = currentFieldInfo.description || '';
  const showLabel = currentFieldInfo.showLabel !== false;
  const showDescription = currentFieldInfo.showDescription !== false;

  // console.log('foreignKeyOptions', foreignKeyOptions);
  // console.log('loadingForeignKeys', loadingForeignKeys);

  // console.log('loadingForeignKeys[fieldName]', loadingForeignKeys[fieldName]);

  const baseSchema = getSchemaFromZod(schema);
  const typeInArray = getZodInnerType(schema);
  const isHidden = schema.description?.hidden || baseSchema.description?.hidden;

  const isAutoSet = autoSetFields && autoSetFields[fieldName] === true;

  const isForeignKey = findForeignKeyOptions(foreignKeyOptions, path) !== null;
  const isForeignKeyLoading = isLoadingForeignKey(loadingForeignKeys, path);
  const isMulti =
    baseSchema instanceof z.ZodArray &&
    (typeInArray instanceof z.ZodString ||
      typeInArray instanceof z.ZodEnum ||
      typeInArray instanceof z.ZodNumber ||
      typeInArray instanceof z.ZodBoolean);

  let content;

  const errorMessage = getNestedError(errors, path);
  // console.log('[path]', path);
  // console.log('errors', errors);
  // console.log('errorMessage', errorMessage);

  if (fieldName === 'id') {
    return null;
  }

  if (fieldName.endsWith('timezone')) {
    content = (
      <FormTimezoneSelector
        fieldKey={name}
        label={label}
        control={control}
        errors={errors}
        description={description}
        disabled={isAutoSet}
      />
    );
  } else if (fieldName.endsWith('hour')) {
    content = (
      <FormHourSelector
        fieldKey={name}
        label={label}
        control={control}
        errors={errorMessage}
        description={description}
        disabled={isAutoSet}
      />
    );
  } else if (isForeignKey || isMulti) {
    content = (
      <FormSelect
        fieldKey={name}
        label={label}
        showLabel={showLabel}
        showDescription={showDescription}
        control={control}
        errors={errorMessage}
        options={
          isForeignKey
            ? findForeignKeyOptions(foreignKeyOptions, path)
            : typeInArray instanceof z.ZodEnum
            ? typeInArray._def.values.map((opt) => ({
                label: typeInArray._def.description?.[String(opt)] || String(opt),
                value: opt,
              }))
            : []
        }
        isMulti={isMulti}
        description={description}
        isLoading={isForeignKeyLoading}
        disabled={isAutoSet}
      />
    );
  } else if (baseSchema instanceof z.ZodBoolean) {
    if (baseSchema._def.description?.true || baseSchema._def.description?.false) {
      const booleanOptions = [
        { value: true, label: baseSchema._def.description.true || 'True' },
        { value: false, label: baseSchema._def.description.false || 'False' },
      ];
      content = (
        <FormSelect
          fieldKey={name}
          label={label}
          description={description}
          showLabel={showLabel}
          showDescription={showDescription}
          control={control}
          errors={errorMessage}
          options={booleanOptions}
          disabled={isAutoSet}
        />
      );
    } else {
      content = (
        <FormCheckbox
          fieldKey={name}
          label={label}
          register={register}
          errors={errorMessage}
          description={description}
          showLabel={showLabel}
          showDescription={showDescription}
          disabled={isAutoSet}
        />
      );
    }
  } else if (baseSchema instanceof z.ZodEnum) {
    if (baseSchema.options.includes('and') && baseSchema.options.includes('or')) {
      content = <AndOrSelector control={control} name={name} label={label} showLabel={showLabel} />;
    } else {
      const enumOptions = baseSchema.options.map((optionValue) => ({
        label: baseSchema.description?.[optionValue] || optionValue,
        value: optionValue,
      }));
      content = (
        <FormSelect
          fieldKey={name}
          label={label}
          description={description}
          showLabel={showLabel}
          showDescription={showDescription}
          control={control}
          errors={errorMessage}
          options={enumOptions}
          disabled={isAutoSet}
        />
      );
    }
  } else if (fieldName.endsWith('_date')) {
    content = (
      <FormDatePicker
        fieldKey={name}
        label={label}
        control={control}
        errors={errors}
        disabled={isAutoSet}
      />
    );
  } else if (
    baseSchema instanceof z.ZodString ||
    baseSchema instanceof z.ZodNumber ||
    (baseSchema instanceof z.ZodUnion &&
      baseSchema._def.options.some((opt) => opt instanceof z.ZodNumber))
  ) {
    content = (
      <FormInput
        fieldKey={name}
        label={label}
        showLabel={showLabel}
        showDescription={showDescription}
        register={register}
        errors={errorMessage}
        type={
          baseSchema instanceof z.ZodNumber ||
          (baseSchema instanceof z.ZodUnion &&
            baseSchema._def.options.some((opt) => opt instanceof z.ZodNumber) &&
            !baseSchema._def.options.some((opt) => opt instanceof z.ZodString))
            ? 'number'
            : 'text'
        }
        description={description}
        disabled={isAutoSet}
      />
    );
  } else {
    console.log('what is the schema', schema);
    console.error(`Unexpected schema type:`, baseSchema);
    return null;
  }

  return isHidden ? <div className="hidden">{content}</div> : content;
};

renderSchemaFlatField.propTypes = {
  schema: PropTypes.instanceOf(z.ZodTypeAny).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  fieldInfo: PropTypes.object,
  foreignKeyOptions: PropTypes.object,
  loadingForeignKeys: PropTypes.object,
  autoSetFields: PropTypes.object,
};

renderSchemaFlatField.defaultProps = {
  errors: {},
  fieldInfo: {},
  foreignKeyOptions: {},
  loadingForeignKeys: {},
  autoSetFields: {},
};

export default renderSchemaFlatField;
