import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import EmptyState from 'components/EmptyState/EmptyState'; // Add this import
import FlexListTable from 'components/FlexListTable/FlexListTable'; // Adjust the import path
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Add this import
import TextBubble from 'components/TextBubble/TextBubble'; // Add this import
import { useUserPermissions } from 'hooks/useUserPermissions'; // Import the useUserPermissions hook
import { PIPELINE_ALL_SOURCE_LIST, PIPELINE_CREATE_FORM } from 'routes/constants';
import { useGetPipelinesQuery } from 'services/rtkApi'; // Adjust the import path

const PipelineTable = () => {
  const location = useLocation();
  const { data, error, isLoading, isFetching, refetch } = useGetPipelinesQuery();
  const { isAdmin, isLoading: isLoadingPermissions } = useUserPermissions();

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  // Optional: Validate or parse data with Zod (if needed)
  // eslint-disable-next-line no-unused-vars
  // const validatedData =
  //   data && data.results.length > 0 ? pipelineSchemaList.parse(data.results[0]) : [];

  const columns = React.useMemo(() => {
    // Base columns that are always shown
    const baseColumns = [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      {
        Header: 'Location',
        accessor: (row) => `${row.gcloud_project_id}.${row.bq_output_dataset}`,
        id: 'location',
      },
      {
        Header: 'Last Run',
        accessor: (row) => {
          const latestRun = row.latest_run;
          if (!latestRun) return null;
          return latestRun.is_run_in_progress ? latestRun.created_at : latestRun.completed_at;
        },
        id: 'last_run',
        Cell: ({ value }) => {
          if (!value) return 'Never';
          return new Date(value).toLocaleString();
        },
      },
      {
        Header: 'Currently Running',
        accessor: (row) => row.latest_run?.is_run_in_progress ?? false,
        id: 'currently_running',
        Cell: ({ value }) => value.toString(),
      },
      {
        Header: 'Pipeline Status',
        accessor: (row) => row.latest_run,
        id: 'pipeline_status',
        Cell: ({ value }) => {
          if (!value) return <TextBubble color="grey" text="Not run" />;
          if (value.is_run_in_progress) return <TextBubble color="blue" text="Currently Running" />;
          return (
            <TextBubble
              color={value.did_run_succeed ? 'green' : 'red'}
              text={value.did_run_succeed ? 'Success' : 'Failed'}
            />
          );
        },
      },
    ];

    // Add account column for admin users
    if (isAdmin) {
      // Insert account column after name
      baseColumns.splice(2, 0, {
        Header: 'Account',
        accessor: 'account',
        id: 'account',
        Cell: ({ value }) => {
          // The account field might be an ID or an object depending on how it's serialized
          if (typeof value === 'object' && value !== null) {
            return value.name || `Account #${value.id}`;
          }
          return `Account #${value}`;
        },
      });
    }

    return baseColumns;
  }, [isAdmin]);

  const linkButtons = [
    {
      label: 'Edit Pipeline',
      link: PIPELINE_ALL_SOURCE_LIST,
    },
  ];

  if (isLoading || isLoadingPermissions) return <LoadingSpinner text="Loading pipelines..." />;
  if (error) return <div>Error loading pipelines</div>;
  if (isFetching) return <LoadingSpinner text="Updating pipelines..." />;

  // Check if data is empty
  if (!data || !data.results || data.results.length === 0) {
    return (
      <EmptyState
        title="No pipelines found"
        description="Get started by creating a new pipeline."
        buttonText="New Pipeline"
        buttonAction={PIPELINE_CREATE_FORM}
      />
    );
  }

  return (
    <div className="mt-4">
      <FlexListTable
        columns={columns}
        data={data.results || []}
        hiddenColumnIds={['id']}
        linkButtons={linkButtons}
      />
      {isFetching && <LoadingSpinner text="Refreshing..." />}
    </div>
  );
};

export default PipelineTable;
