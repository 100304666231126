/**
 * Hook to initialize and manage Paddle instance
 * Handles initialization, environment setup, and provides the Paddle instance
 */
import { initializePaddle } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';
import { PADDLE_CONFIG } from './paddleConstants';

export function usePaddleInstance(onEventCallback) {
  const [paddle, setPaddle] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initializePaddle({
      environment: PADDLE_CONFIG.ENVIRONMENT,
      token: PADDLE_CONFIG.CLIENT_TOKEN,
      eventCallback: onEventCallback,
    })
      .then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        } else {
          setError('Failed to initialize Paddle');
        }
      })
      .catch((err) => {
        setError(`Failed to initialize Paddle: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onEventCallback]);

  return {
    paddle,
    error,
    isLoading,
  };
}
