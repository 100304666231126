/**
 * Displays current subscription details and management options using ListStacked component
 */

import Alert from 'components/Alert/Alert';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import CancelPlanModal from '../CancelPlanModal';
import PricingTableUpgrade from '../PricingTables/PricingTableUpgrade';
import { FEATURE_SECTIONS, PRICING_TIERS_DEV, PRICING_TIERS_PROD } from '../pricingV1Constants';
import SubscriptionBox from '../SubscriptionBox/SubscriptionBox';

import { useManageYourPlan } from './useManageYourPlan';

export default function ManageYourPlan() {
  const pricingTiers = window.IS_DEV ? PRICING_TIERS_DEV : PRICING_TIERS_PROD;

  const {
    // Destructure everything we need from the hook
    selectedSubscription,
    isModalOpen,
    isChangingPlan,
    isLoading,
    hasRecentChanges,
    messageRef,
    subscriptions,
    activePlans,
    isLoadingSubscriptions,
    isLoadingActivePlans,
    isUpdating,
    error,
    selectedItem,
    handleChangePlan,
    handleRemoveItem,
    handleCloseModal,
    handleActionComplete,
    handleAddonChange,
    changeSubscriptionItem,
    hasAddons,
    handleBackToSubscription,
    handlePlanChangeError,
    handleSubscriptionChange,
    refetch,
  } = useManageYourPlan();

  // Loading and error states
  if (isLoadingSubscriptions) return <LoadingSpinner text="Loading subscription details..." />;
  if (error) return <div>Error loading subscription</div>;

  // Show plan change view when upgrading
  if (isChangingPlan && selectedSubscription) {
    return (
      <div>
        {/* Back link */}
        <div className="mb-4">
          <a
            href="#"
            className="text-gray-600 hover:text-gray-900"
            onClick={(e) => {
              e.preventDefault();
              handleBackToSubscription();
            }}
          >
            ← Back to Subscription
          </a>
        </div>

        {hasAddons(selectedSubscription) && (
          <div className="mb-4">
            <Alert
              status="warning"
              message="When changing billing intervals (monthly/yearly), all add-ons will automatically be moved to the same interval."
            />
          </div>
        )}

        <PricingTableUpgrade
          initialTiers={pricingTiers}
          sections={FEATURE_SECTIONS}
          existingSubscription={selectedSubscription}
          changeSubscriptionItem={async (newPlan) => {
            try {
              await changeSubscriptionItem(newPlan);
              await handleActionComplete('Your plan has been updated successfully');
            } catch (error) {
              handlePlanChangeError();
              // Optionally show error message
            }
          }}
        />
      </div>
    );
  }

  // console.log('subscriptions', subscriptions);
  // console.log('activePlans', activePlans);

  /**
   * Calculates the action buttons for a subscription based on its active plans
   * @param {Object} subscription - The subscription object
   * @param {Array} subscriptionActivePlans - List of active plans for this subscription
   * @returns {Array} Array of button configurations
   */
  const getSubscriptionButtons = (subscription, subscriptionActivePlans) => {
    return subscriptionActivePlans?.some((plan) => plan.is_currently_active)
      ? [
          {
            label: 'Change Plan',
            variant: 'primary',
            onClick: (item) => handleChangePlan(subscription),
          },
          {
            label: 'Cancel',
            variant: 'danger',
            isOutline: true,
            onClick: (item) => handleRemoveItem(subscription, item),
          },
        ]
      : [];
  };

  // Main render with loading states, messages, and subscription list
  return (
    <>
      {isLoading && (
        <div className="flex justify-center py-8">
          <LoadingSpinner text="Updating subscription details..." size={12} isFullWidth />
        </div>
      )}

      {!isLoading && messageRef.current.message && (
        <div className="mb-8 w-full">
          <Alert
            status={messageRef.current.type === 'success' ? 'success' : 'error'}
            message={messageRef.current.message}
          />
        </div>
      )}

      {!isLoading && hasRecentChanges && (
        <Alert
          status="default"
          message="You might need to refresh this page and wait a minute to see the latest subscription details. (Depending how fast our payment processor is.)"
          className="mb-8"
        />
      )}

      {!isLoading && (
        <div className="space-y-10 divide-y divide-gray-900/10 w-full">
          <div className="grid grid-cols-1 gap-6 max-w-2xl">
            {subscriptions?.results.map((subscription) => {
              const subscriptionActivePlans = activePlans?.filter(
                (plan) => plan.subscription_id === subscription.id
              );

              return (
                <SubscriptionBox
                  key={subscription.id}
                  subscription={subscription}
                  activePlans={subscriptionActivePlans}
                  isLoadingActivePlans={isLoadingActivePlans}
                  buttons={getSubscriptionButtons(subscription, subscriptionActivePlans)}
                  disabled={isUpdating}
                  onAddonChange={(quantity) => handleAddonChange(subscription.id, quantity)}
                  refetch={refetch}
                  onChangeComplete={handleSubscriptionChange}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* Cancel confirmation modal */}
      <CancelPlanModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        subscription={selectedSubscription}
        itemToRemove={selectedItem}
        onSuccess={() => handleActionComplete('Your plan has been cancelled successfully')}
      />
    </>
  );
}
