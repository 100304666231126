import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import FormBooleanNonHookForm from 'components/FormNonReactHook/FormBooleanNonHookForm';
import ModalAction from 'components/ModalAction/ModalAction';
import {
  useDeleteGA4PipelineTablesMutation,
  useDeletePipelineDatasetMutation,
  useGetCurrentUserQuery,
  useGetPipelineByIdQuery,
  useGetSourceGA4BigQueriesQuery,
  useRunPipelineMutation,
  useUpdatePipelineDbtProjectMutation,
} from 'services/rtkApi';

/**
 * Custom run modal component for admin users
 */
const CustomRunModal = ({ isOpen, onClose, onRun, isLoading }) => {
  const [fullRefresh, setFullRefresh] = useState(false);
  const [recalculateArgs, setRecalculateArgs] = useState(false);

  return (
    <ModalAction
      isOpen={isOpen}
      onClose={onClose}
      title="Custom Pipeline Run"
      description={
        <div className="space-y-4">
          <p>Configure custom run options:</p>
          <div className="space-y-2">
            <FormBooleanNonHookForm
              id="full-refresh"
              label="Full Refresh"
              value={fullRefresh}
              onChange={setFullRefresh}
            />
            <FormBooleanNonHookForm
              id="recalculate-args"
              label="Recalculate Arguments"
              value={recalculateArgs}
              onChange={setRecalculateArgs}
            />
          </div>
        </div>
      }
      actionType="create"
      buttons={[
        {
          text: 'Run Pipeline',
          onClick: () => onRun({ fullRefresh, recalculateArgs }),
          variant: 'primary',
          loading: isLoading,
        },
        {
          text: 'Cancel',
          onClick: onClose,
          variant: 'secondary',
          disabled: isLoading,
        },
      ]}
    />
  );
};

CustomRunModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRun: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

/**
 * Renders a button strip for pipeline actions, including "Manually Run Pipeline" and "Update dbt Project".
 */
const PipelineActions = ({ pipelineId, isDisabled, className, latestRun, isRunInProgress }) => {
  const [runPipeline, { isLoading }] = useRunPipelineMutation();
  const [updatePipelineDbtProject, { isLoading: isUpdatingDbtProject }] =
    useUpdatePipelineDbtProjectMutation();
  const { data: user } = useGetCurrentUserQuery();
  const [isCustomRunModalOpen, setIsCustomRunModalOpen] = useState(false);

  // Get GA4 sources and if there are none, disable the run pipeline button
  const { data: ga4Sources, isLoading: isLoadingGA4Sources } = useGetSourceGA4BigQueriesQuery(
    pipelineId ? { pipeline: pipelineId } : {}
  );
  const hasNoGA4Sources =
    !isLoadingGA4Sources && (!ga4Sources?.results || ga4Sources.results.length === 0);

  // Check if setup is complete
  // We can only handle one GA4 per pipeline.
  const isSetupComplete = ga4Sources?.results?.[0]?.current_pipeline_build_status === 'complete';

  const handleRunPipeline = async (options = {}) => {
    try {
      await runPipeline({
        id: pipelineId,
        run_type: 'ga4',
        full_refresh: options.fullRefresh || false,
        recalculate_args: options.recalculateArgs || false,
      });
      if (options.closeModal) {
        setIsCustomRunModalOpen(false);
      }
    } catch (error) {
      console.error('Failed to run pipeline:', error);
    }
  };

  const handleUpdateDbtProject = async () => {
    try {
      await updatePipelineDbtProject(pipelineId).unwrap();
    } catch (error) {
      console.error('Failed to update dbt project:', error);
    }
  };

  const { id } = useParams();
  const { data: pipeline } = useGetPipelineByIdQuery(id);

  const getBigQueryUrl = () => {
    if (!pipeline?.gcloud_project_id || !pipeline?.bq_output_dataset) return null;
    return `https://console.cloud.google.com/bigquery?ws=!1m4!1m3!3m2!1s${pipeline.gcloud_project_id}!2s${pipeline.bq_output_dataset}`;
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteDatasetModalOpen, setIsDeleteDatasetModalOpen] = useState(false);
  const [deleteGA4Tables, { isLoading: isDeleting }] = useDeleteGA4PipelineTablesMutation();
  const [deletePipelineDataset, { isLoading: isDeletingDataset }] =
    useDeletePipelineDatasetMutation();

  const handleDeleteGA4Tables = async (include_copied_events) => {
    try {
      await deleteGA4Tables({
        id: pipelineId,
        include_copied_events,
      });
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete GA4 tables:', error);
    }
  };

  const handleDeleteDataset = async () => {
    try {
      await deletePipelineDataset(pipelineId);
      setIsDeleteDatasetModalOpen(false);
    } catch (error) {
      console.error('Failed to delete dataset:', error);
    }
  };

  const [isLookerModalOpen, setIsLookerModalOpen] = useState(false);

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="flex items-center space-x-4">
        <h4 className="text-sm font-medium w-24">Process</h4>
        <div className="flex space-x-4">
          <Button
            onClick={() => handleRunPipeline()}
            loading={isLoading}
            disabled={isDisabled || isLoading || isRunInProgress || hasNoGA4Sources}
            variant="primary"
            isOutline
          >
            {isRunInProgress ? 'Pipeline Running...' : 'Run Pipeline'}
          </Button>
          {user?.is_superuser && (
            <>
              <Button
                onClick={() => setIsCustomRunModalOpen(true)}
                loading={isLoading}
                disabled={isDisabled || isLoading || isRunInProgress || hasNoGA4Sources}
                variant="primary"
                isOutline
              >
                {isRunInProgress ? 'Pipeline Running...' : 'Run Pipeline (Custom)'}
              </Button>
              <Button
                onClick={handleUpdateDbtProject}
                loading={isUpdatingDbtProject}
                disabled={isUpdatingDbtProject}
                variant="primary"
                isOutline
              >
                Update dbt Project
              </Button>
            </>
          )}
          <Button onClick={() => setIsDeleteModalOpen(true)} variant="danger" isOutline>
            Delete GA4 Tables
          </Button>
          <Button onClick={() => setIsDeleteDatasetModalOpen(true)} variant="danger" isOutline>
            Delete Dataset
          </Button>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <h3 className="text-sm font-medium w-24">View</h3>
        <div className="flex space-x-4">
          <Button
            href={getBigQueryUrl()}
            disabled={isLoading || !getBigQueryUrl()}
            variant="primary"
            openInNewTab
            isOutline
          >
            View in BigQuery
          </Button>

          <Button
            onClick={() => setIsLookerModalOpen(true)}
            variant="primary"
            disabled={!isSetupComplete}
            isOutline
          >
            Looker Studio Connector
          </Button>
        </div>
      </div>

      <CustomRunModal
        isOpen={isCustomRunModalOpen}
        onClose={() => setIsCustomRunModalOpen(false)}
        onRun={(options) => handleRunPipeline({ ...options, closeModal: true })}
        isLoading={isLoading}
      />

      <ModalAction
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete GA4 Tables for Pipeline"
        description="Choose how you want to delete the GA4 tables. Including events will delete all copied event tables from the raw dataset."
        actionType="delete"
        buttons={[
          {
            text: 'Delete Tables (Including Events)',
            onClick: () => handleDeleteGA4Tables(true),
            variant: 'danger',
            loading: isDeleting,
          },
          {
            text: 'Delete Tables (Excluding Events)',
            onClick: () => handleDeleteGA4Tables(false),
            variant: 'primary',
            loading: isDeleting,
          },
          {
            text: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            variant: 'secondary',
            disabled: isDeleting,
          },
        ]}
      />

      <ModalAction
        isOpen={isDeleteDatasetModalOpen}
        onClose={() => setIsDeleteDatasetModalOpen(false)}
        title="Delete Pipeline Dataset"
        description="This will delete the pipeline's dataset and raw dataset. This action cannot be undone."
        actionType="delete"
        buttons={[
          {
            text: 'Delete Dataset',
            onClick: handleDeleteDataset,
            variant: 'danger',
            loading: isDeletingDataset,
          },
          {
            text: 'Cancel',
            onClick: () => setIsDeleteDatasetModalOpen(false),
            variant: 'secondary',
            disabled: isDeletingDataset,
          },
        ]}
      />

      <ModalAction
        isOpen={isLookerModalOpen}
        onClose={() => setIsLookerModalOpen(false)}
        title="Looker Studio Connector Details"
        actionType="create"
        description={
          <div className="space-y-4 ">
            <ul className="list-disc pl-8 ">
              <li>
                <strong>Link: </strong>
                <a
                  href="https://datastudio.google.com/datasources/create?connectorId=AKfycbwmJ61PQUIim709LqiS27ekmiQuWEj2Tk9eQu4uxZY0KRE3PdH8fSmFjBg7XAc913rwkw"
                  className="text-deep-sea-green-900 underline hover:text-deep-sea-green-800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Create Looker Studio Data Source
                </a>
              </li>
              <li>
                <strong>Project: </strong>
                {pipeline?.gcloud_project_id}
              </li>
              <li>
                <strong>Dataset: </strong>
                {pipeline?.bq_output_dataset}
              </li>
            </ul>
          </div>
        }
        buttons={[
          {
            text: 'Close',
            onClick: () => setIsLookerModalOpen(false),
            variant: 'secondary',
          },
        ]}
      />
    </div>
  );
};

PipelineActions.propTypes = {
  pipelineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  latestRun: PropTypes.shape({
    id: PropTypes.number,
    did_run_succeed: PropTypes.bool,
    is_run_in_progress: PropTypes.bool,
  }),
  isRunInProgress: PropTypes.bool.isRequired,
};

PipelineActions.defaultProps = {
  isDisabled: false,
  className: '',
  latestRun: null,
};

export default PipelineActions;
