import PropTypes from 'prop-types';
import React from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable';
import TextBubble from 'components/TextBubble/TextBubble';

/**
 * Table displaying account information
 */
const AccountsTable = ({ accounts }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Account Name',
        accessor: 'name',
      },
      {
        Header: 'Owner Email',
        accessor: 'email',
      },
      {
        Header: 'Pipelines',
        accessor: 'pipelineCount',
      },
      {
        Header: 'Plan Status',
        accessor: 'subscription_status',
        Cell: ({ value }) => {
          const colorMap = {
            active: 'green',
            canceled: 'red',
            past_due: 'red',
            paused: 'grey',
            trialing: 'blue',
          };
          return value ? (
            <TextBubble color={colorMap[value] || 'grey'} text={value?.toUpperCase()} />
          ) : (
            <TextBubble color="red" text="NO PLAN" />
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
    ],
    []
  );

  if (!accounts || accounts.length === 0) {
    return <div className="text-center text-gray-500 py-4">No accounts found</div>;
  }

  return (
    <FlexListTable
      columns={columns}
      data={accounts}
      initialSort={[{ id: 'created_at', desc: true }]}
    />
  );
};

AccountsTable.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      pipelineCount: PropTypes.number.isRequired,
      subscription_status: PropTypes.string,
      created_at: PropTypes.string.isRequired,
    })
  ),
};

export default AccountsTable;
