import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

/**
 * Universal link component that automatically handles external vs internal links
 * @param {Object} props
 * @param {string} props.href - URL to link to
 * @param {React.ReactNode} props.children - Link content
 * @param {string} props.className - Additional CSS classes
 * @param {Object} props.rest - Any additional props to pass to the link
 */
const LinkCustom = ({ href, children, className = '', ...rest }) => {
  // Check if the URL is external (starts with http/https or //)
  const isExternal = href && (href.startsWith('http') || href.startsWith('//'));

  // Default link styling
  const defaultClassName = 'text-deep-sea-green-600 hover:text-deep-sea-green-500';
  const combinedClassName = `${defaultClassName} ${className}`.trim();

  // Render external link with security attributes
  if (isExternal) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={combinedClassName}
        {...rest}
      >
        {children}
      </a>
    );
  }

  // Render internal link using React Router
  return (
    <RouterLink to={href} className={combinedClassName} {...rest}>
      {children}
    </RouterLink>
  );
};

LinkCustom.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default LinkCustom;
