import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { showToastLink } from 'components/ToasterLink/ToasterLink';
import { HOME } from 'routes/constants';
import { getGoogleAPIAuthGetRedirectUrl, getGoogleAPIAuthStatus } from 'services/auth/auth';
import { setGoogleAPIAuthStatus } from 'services/auth/authSlice';
import { clearFormError } from 'services/errors/errorSlice';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

import GCloudPermissionsChecker from './components/GCloudPermissionsChecker';

const GoogleAccessContent = ({ isAuthorized, googleAPIAuthRegisterErrors, handleGoogleLogin }) => (
  <>
    <div className="">
      {isAuthorized ? (
        <p className="mt-0">
          Thank you for granting us Google access. If you need to re-authorize, you can use the
          button below.
        </p>
      ) : (
        <>
          <p>We need some permissions to auto fill our forms and work with your data.</p>
          <p>Specifically we&apos;re asking for permissions to GA4 and BigQuery.</p>
          <p>If you&apos;d prefer to manage this through a service account please get in touch.</p>
        </>
      )}
    </div>
    {googleAPIAuthRegisterErrors.length > 0 && (
      <div className="bg-red-100 border border-red-400 text-red-700 rounded p-4 mt-4">
        {googleAPIAuthRegisterErrors.map((error, index) => (
          <p key={index}>{error}</p>
        ))}
      </div>
    )}
    <div className="sm:mx-auto space-y-6">
      <Button
        onClick={handleGoogleLogin}
        variant="primary"
        className=""
        statusColour={isAuthorized ? 'success' : ''}
      >
        {isAuthorized ? 'Re-authorize Google Access' : 'Share Access'}
      </Button>
    </div>
  </>
);

GoogleAccessContent.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  googleAPIAuthRegisterErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleGoogleLogin: PropTypes.func.isRequired,
};

const GoogleAPIAuthRegister = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Get any errors that were sent to redux
  const formErrors = useSelector((state) => state.error.formErrors);
  const googleAPIAuthRegisterErrors = formErrors?.googleAPIAuthRegister || [];

  /**
   * Begin the oauth2 flow.
   */
  const handleGoogleLogin = async () => {
    dispatch(clearFormError('googleAPIAuthRegister'));
    try {
      const redirectUrl = await getGoogleAPIAuthGetRedirectUrl();
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Failed to get Google API auth redirect URL:', error);
    }
  };

  // Move checkAuthStatus outside useEffect so we can reuse it
  const checkAuthStatus = async () => {
    try {
      const status = await getGoogleAPIAuthStatus();
      setIsAuthorized(status?.is_authenticated);
      // Update Redux state to keep it consistent
      dispatch(setGoogleAPIAuthStatus(status?.is_authenticated));
    } catch (error) {
      console.error('Failed to get Google API auth status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial auth check
  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Check for success parameter and show toast
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');

    if (status === 'success') {
      checkAuthStatus(); // Refetch auth status when URL status changes
      showToastLink({
        message: 'Thanks for authorizing! Head to the homepage to continue with Pipeline.',
        url: HOME,
        toastOptions: { type: 'success' },
      });
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">
            Share permission with us
          </h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip hasFlex={false}>
          {isLoading ? (
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
          ) : (
            <GoogleAccessContent
              isAuthorized={isAuthorized}
              googleAPIAuthRegisterErrors={googleAPIAuthRegisterErrors}
              handleGoogleLogin={handleGoogleLogin}
            />
          )}
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">
            Does your account have the right permissions?
          </h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="">
            <GCloudPermissionsChecker key={isAuthorized} />
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInner />
      </div>
    </PageLayout>
  );
};

export default GoogleAPIAuthRegister;
