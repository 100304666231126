import { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import Alert from 'components/Alert/Alert';
import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  PipelineFormStateProvider,
  usePipelineFormState,
} from 'pages/models/pipelines/components/PipelineFormStateProvider';
import {
  autoSetFields,
  fieldInfoFormPipeline,
  pipelineSchemaForm,
} from 'pages/models/pipelines/schema/pipelineSchema';
import { GOOGLE_API_PERMISSIONS_REGISTER } from 'routes/constants';
import {
  useAddPipelineMutation,
  useGetPipelineByIdQuery,
  useUpdatePipelineMutation,
} from 'services/rtkApi';
import { hasAnyPermissionErrors } from 'utils/index';

import { useBigQueryProjectAndDataset } from '../hooks/useBigQueryProjectAndDataset';

const PipelineForm = ({ onSuccess, manual }) => {
  const { id } = useParams();
  const location = useLocation();
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  const mutationHook = isUpdate ? useUpdatePipelineMutation : useAddPipelineMutation;
  const queryHook = isUpdate ? useGetPipelineByIdQuery : undefined;

  /**
   * This chunk of the form is trying to handle the quadfecta for bullshit. It
   * encompases 4 things.
   *
   * 1. We make one request, then this needs to trigger another.
   * 2. It needs to not re-render infinitely.
   * 3. When you create it has to run on field change.
   * 4. When you update it has to run on load as well as on field change.
   *
   * Trying to get this working has been brutal, probably because my understand of react
   * hook form is not the best.
   *
   * Here is where we got to.
   *
   * 1. We wrap the entire form in a state provide. This lets us monitor the form value changes.
   * 2. We don't actually use formValues because they're sometimes wrong. Instead we always
   *    use method.GetValues to get the current values.
   */
  const methods = usePipelineFormState();
  const { control } = methods;
  const formValues = useWatch({ control });

  // Add this useEffect for debugging
  const [projectId, setProjectId] = useState(null);

  // Replace the existing useEffect with this one
  useEffect(() => {
    const currentValues = methods.getValues();
    const currentProjectId = currentValues.gcloud_project_id;

    if (currentProjectId !== projectId) {
      setProjectId(currentProjectId);
    }

    // Optionally, keep the console.log for debugging
  }, [methods, formValues]);

  /**
   * Now we have the project ID changing whenever we load or it changes
   * we pass this into our hook.
   */
  const queryParams = {
    project_id: projectId,
    ignore_ga4_raw_exports: true,
  };
  const {
    foreignKeyOptions,
    loadingForeignKeys,
    refetchDatasets,
    isAuthorized,
    errors, // Add this to destructure errors from the hook
  } = useBigQueryProjectAndDataset(queryParams);

  useEffect(() => {
    // Force refetch when component mounts or location changes
    if (isAuthorized) {
      refetchDatasets();
    }
  }, [location, refetchDatasets, isAuthorized]);

  const postOnChange = useCallback(
    (formValues, changedFields) => {
      if (!isAuthorized) return null;

      if ('bq_output_dataset' in changedFields) {
        const selectedDataset = foreignKeyOptions
          .find((option) => option.key[0] === 'bq_output_dataset')
          ?.options.find((dataset) => dataset.value === changedFields.bq_output_dataset);

        if (selectedDataset) {
          return [{ path: 'gcloud_region', value: selectedDataset.location }];
        }
      }
      return null;
    },
    [foreignKeyOptions, isAuthorized]
  );

  if (!isAuthorized) {
    return (
      <CommonActionSingle
        title="Google BigQuery Permissions Required"
        description="To create a pipeline, you need to authorize access to your Google BigQuery account."
        buttonName="Set Up Permissions"
        href={GOOGLE_API_PERMISSIONS_REGISTER}
        className="mt-4"
      />
    );
  }
  /**
   * If we're in manual mode. We don't need to fetch the foreign key options.
   */
  let foreignKeyOptionsInput = foreignKeyOptions;
  let loadingForeignKeysInput = loadingForeignKeys;
  let autoSetFieldsInput = autoSetFields;
  if (manual) {
    foreignKeyOptionsInput = [];
    loadingForeignKeysInput = [];
    autoSetFieldsInput = [];
  }

  /**
   * Handle permission errors
   */
  const hasPermissionError = hasAnyPermissionErrors(errors || {});

  /**
   * Set the region based on the selected dataset.
   */
  return (
    <PipelineFormStateProvider>
      <div className="mt-4">
        {hasPermissionError && (
          <div className="mb-4">
            <Alert
              status="error"
              message="You need to give your account the correct permissions in BigQuery."
              linkText="View documentation"
              linkHref="https://www.pipedout.com/documentation/setup-bigquery-permissions"
            />
          </div>
        )}
        <CrudForm
          entityId={id}
          key={`pipeline`}
          methods={methods}
          formType={formType}
          mutationHook={mutationHook}
          queryHook={queryHook}
          schema={pipelineSchemaForm}
          fieldInfo={fieldInfoFormPipeline}
          foreignKeyOptions={foreignKeyOptionsInput}
          loadingForeignKeys={loadingForeignKeysInput}
          postOnChange={postOnChange}
          tabs={[
            {
              key: 'Main',
              tabName: 'Main',
              fieldList: [
                'name',
                'gcloud_project_id',
                'bq_output_dataset',
                'gcloud_region',
                'timezone',
                'run_hour',
                'auto_run_on_new_ga4_tables',
              ],
              description: (
                <div>
                  <p>
                    You&apos;ll typically have one pipeline per site/reporting view, so call this
                    something like &apos;My Brand&apos;
                  </p>
                </div>
              ),
            },
          ]}
          onSuccess={onSuccess}
          autoSetFields={autoSetFieldsInput}
        />
      </div>
    </PipelineFormStateProvider>
  );
};

export default PipelineForm;
