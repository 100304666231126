/**
 * Extracts and formats current items from a Paddle subscription
 * @param {Object} subscription - Paddle subscription object
 * @returns {Array} Array of formatted items for price preview
 */
export const getCurrentItems = (subscription) => {
  if (!subscription?.items?.length) return [];

  return subscription.items
    .filter((item) => item.status === 'active')
    .map((item) => ({
      priceId: item.price.id,
      quantity: item.quantity,
      total: parseInt(item.price.unit_price.amount) * item.quantity,
    }));
};

/**
 * Formats price preview items for Paddle API
 * @param {Array} items - Array of items with priceId and quantity
 * @returns {Array} Formatted items for Paddle price preview
 */
export const formatPreviewItems = (items) => {
  return items.map((item) => ({
    priceId: item.priceId,
    quantity: item.quantity,
  }));
};
